import React, { useState, useEffect, useRef } from "react";
import { Box, Dialog, DialogContent, Drawer, Grid, Hidden, Modal, Paper, Slide, Stack, Skeleton, Avatar } from "@mui/material";
import NavbarStyles from './NavbarStyles';
import SearchGlobal from '../../assets/svg/search2.svg';
import Profile from '../../assets/svg/profile.svg';
import AccountMenu from "../menu/AccountMenu";
import LocalStorage from "../../utils/LocalStorage";
import Notifications from "../../views/settings/notifications/NotificationPopup";
import { Divider } from "rsuite";
import AdminRoute from "../../routes/AdminRoute";
import { useLocation, useNavigate } from "react-router";
import FileSVG from '../../assets/svg/File.svg';
import logoName from '../../assets/svg/logoName.svg';
import Button from "../../components/customButton/Button";
import Text from "../../components/customText/Text";
import offBoardSave from "../../assets/svg/offBoardSave.svg"
import styled from '@emotion/styled';
import EmployeeRoute from "../../routes/EmployeeRoute";
import MenuIcon from '@mui/icons-material/Menu';
import EmployeesLogo from '../../assets/svg/employees.svg';
import DashboardLogo from '../../assets/svg/dashboard.svg';
import PlacementLogo from '../../assets/svg/placement.svg';
import clientLogo from '../../assets/svg/clientIcon.svg';
import TimeSheetLogo from "../../assets/svg/TimesheetIcon.svg"
// import logo from '../../assets/images/codetru-sidebar.png';
import { domain } from "../../config/Domain"; // eslint-disable-next-line
import { addLoader, addLoaderPlanExpired, LinearProgressBundle, removeExpiredLoader, removeLoader } from "../../utils/utils";
// import urlLogo from '../../assets/logo.svg';
// import FiHevronLeft from '../../assets/svg/fi-hevron-left.svg';
import bundlelogo from '../../assets/timeSheets/bundlelogo.svg';
// import credits from '../../assets/timeSheets/Credits.svg';
import star from '../../assets/timeSheets/Ai-bundle-star.svg';
import DashboardAPI from "../../apis/admin/DashboardAPI"; // eslint-disable-next-line
import { e } from "mathjs";

function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible
    );
    const [tool, setTool] = useState(true)
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, tool, setTool, setIsComponentVisible };
}

export default function App(props) {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(true);
    const classes = NavbarStyles();
    const location = useLocation();
    const [state, setState] = useState({
        search: ""
    })
    const [searchData, setsearchData] = useState([])
    const navigate = useNavigate();
    const [openAI, setOpenAI] = useState(false);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialog-paper ": {
            borderRadius: "16px",
            width: "500px"
        }
    }));// eslint-disable-next-line
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });// eslint-disable-next-line
    const [openPopup, setOpenpopup] = useState(false)
    const [loader, setLoader] = useState(false)
    const [openDrawer, setopenDrawer] = useState(false)
    const [url, setUrl] = useState('')
    let formsIncude = ['/employees/onboard', '/employees/add', '/timesheet/add-timesheet', '/placements/addclientAndendclient']

    const style = {
        position: 'absolute',
        top: '152px',
        right: 0,
        // left: '70%',
        transform: 'translate(-50%, -50%)',
        width: '317px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px !important',
        outline: 'none',
        margin: '5px'
        // [useTheme().breakpoints.down('lg')]: {
        //     top: '24%',
        //     left: '76%',
        //     minWidth: '317px',
        // }
    };

    const [planDetails, setplanDetails] = useState({
        prompt_payment_slug: '',
        ai_ts_payment_slug: '',
        ai_ts_access_credits: 0,
        prompt_access_credits: 0,
        ai_usedCredits: 0,
        prompt_usedCredits: 0,
        ai_timesheet_access_paid_credits: 0,
        prompt_access_paid_credits: 0,
        subscription_expiring: ''
    });

    const getPlanCheck = () => {
        setLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setLoader(false)
                setplanDetails({
                    ...res.data,
                    ai_usedCredits: res.data.ai_ts_access_credits - res.data.ai_timesheet_access_paid_credits,
                    prompt_usedCredits: res.data.prompt_access_credits - res.data.prompt_access_paid_credits
                })
                LocalStorage.setDateFormat(res.data.date_format)
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
            else {
                setLoader(false)
            }
        })
    }

    useEffect(() => {
        getPlanCheck();
        // eslint-disable-next-line
    }, [])

    function percentage(percent, total) {
        let value = ((percent / total) * 100)
        return value ? value.toFixed(2) : 0
    }

    const handleChange = (event) => {
        if (LocalStorage.getUserData().org_config_set == false) {
            return true
        }
        else {
            const inputvalue = event.target.value
            setState({
                ...state,
                [event.target.name]: event.target.value
            })
            var routesList = LocalStorage.getUserData().admin_login ? AdminRoute : EmployeeRoute
            var routeList1 = routesList.filter(item => ((item.name !== '' || item.name !== undefined) && item.access))
            const searchAdmin = routeList1.filter(item => item.name.toLowerCase().includes(inputvalue.toLowerCase()));
            setIsComponentVisible(true)
            // const searchConfigure = ConfigurationRoute.filter(item => item.name.toLowerCase().includes(inputvalue.toLowerCase()));
            // const arr = searchAdmin.concat(searchConfigure)
            setsearchData([...searchAdmin]);
        }

    }
    const [searchItem, setSearchItem] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const navigateToPath = (item) => {
        setUrl(item.path);
        if (formsIncude.includes(location.pathname)) {
            setOpenpopup(true);
            setIsComponentVisible(false)
        }
        else {
            navigate(`${item.path}`); setState({ ...state, search: '' });

        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();

            let currentIndex = selectedIndex < searchData.length - 1 ? selectedIndex + 1 : selectedIndex
            setSelectedIndex(currentIndex);
            setState({
                ...state,
                search: searchData[currentIndex].name,
            });
            setSearchItem(searchData[currentIndex]);
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let currentIndex = selectedIndex > 0 ? selectedIndex - 1 : selectedIndex
            setSelectedIndex(currentIndex);
            setState({
                ...state,
                search: searchData[selectedIndex].name,
            });
            setSearchItem(searchData[currentIndex]);
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            event.preventDefault();
            // setState({
            //     ...state,
            //     search: searchData[selectedIndex].name,
            // });
            setIsComponentVisible(false);
            navigateToPath(searchData[selectedIndex]);
            setSelectedIndex(-1);
            setSearchItem({});
            setState({ ...state, search: '' });
        }
    };



    useEffect(() => {
        setState({ ...state, search: '' });
        // eslint-disable-next-line
    }, [isComponentVisible])


    const handleSearchItem = () => {
        if (searchItem.path !== "" && searchItem.path !== undefined && searchItem.path !== null) {
            navigateToPath(searchItem);
            setState({ ...state, search: '' });
            setSearchItem({});
            setSelectedIndex(-1);
        }
    };



    let urlLogo = props.logoUrl != undefined ? props.logoUrl : LocalStorage.getUserData().logo_url ? LocalStorage.getUserData().logo_url : logoName
    return (
        <Box component={'nav'} className={classes.nav} ref={ref}>
            <div style={{ width: "100%" }}> {(location.pathname == "/ocr/bulk-upload-timesheets" || location.pathname == "/employee/verify") && <img src={urlLogo} alt="Logo" style={{ height: "54px", width: '54px', borderRadius: '50%' }} />}</div>
            <Hidden mdUp>
                <MenuIcon onClick={() => { setopenDrawer(true) }} />
            </Hidden>
            <div id='globalsearch'>
            {location.pathname != "/ocr/bulk-upload-timesheets" && location.pathname != "/employee/verify" &&
                <div className={classes.searchField} >
                    <input
                        type="text"
                        className={classes.globalSearchInput}
                        placeholder="Search or Jump to..."
                        name='search'
                        value={state.search}
                        onChange={handleChange}
                        autocomplete="off"
                        onKeyDown={handleKeyDown}

                    />
                    <button
                        type="button"
                        className={classes.searchIcon}
                        onClick={handleSearchItem}
                        style={{ cursor: searchItem && state.search !== "" ? 'pointer' : 'not-allowed' }}
                    >
                        <img src={SearchGlobal} alt="SearchGlobal" />
                    </button>
                </div>
            }
            {isComponentVisible && state.search != '' ?
                <Paper className={classes.SearchList}>
                    {searchData.map((item, index) => (
                        <>
                            <Box className={`${classes.mainSearchBar} ${index === selectedIndex ? classes.selectedItem : ''}`} onClick={() => { navigateToPath(item) }}>
                                <Box className={classes.innerBoxname}><img src={FileSVG} alt='FileSVG' style={{ marginRight: '15px' }} /> {item.name}</Box>
                                <Box className={classes.innerBoxmain}>{item.main ? item.main : 'main'}</Box>
                            </Box>
                            <Divider></Divider>
                        </>
                    ))}

                </Paper>
                : ''
            }</div>
            <div className={classes.rightItems}>
                {
                    LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}>

                            <Box onClick={() => { setOpenAI(true); getPlanCheck() }} >
                                <Avatar src={bundlelogo} />
                            </Box>

                        </Box> : ''
                }

                {/* <Box sx={{
                    color: '#FFFFFF', fontSize: '14px', fontWeight: '400', textAlign: 'center', cursor: 'pointer'
                }}>
                    <img src={bundlelogo} alt="bundlelogo" style={{ cursor: 'pointer' }}  />
                </Box> */}
                <Modal
                    open={openAI}
                    onClose={() => setOpenAI(false)}
                >
                    <Box sx={style}>
                        {
                            loader ?
                                <Grid container sx={{ padding: '14px 12px 14px 12px', width: '100%', borderRadius: '16px', border: '3px solid #007050 !important', }}>
                                    {[0, 1, 2].map((i) => (
                                        < Skeleton animation='wave' width='100%' height='40px' />
                                    ))
                                    }
                                </Grid>
                                :
                                <Grid container sx={{ padding: '14px 12px 14px 12px', width: '100%', borderRadius: '16px', border: '3px solid #007050 !important', }}>
                                    <Grid item container xl={12} lg={12} xs={12} spacing={2} pr={1} alignItems='center'>
                                        <Grid item lg={1} xs={2}>
                                            <img src={star} alt="star" />
                                        </Grid>
                                        <Grid item lg={7} xs={7} mt={-0.7}>
                                            <Text black12>AI Credits</Text>
                                        </Grid>
                                        {LocalStorage.getUserData().super_admin ?
                                            <Grid item lg={2} xs={2} textAlign='center' ml={1} mt={-0.5}>
                                                <Box className={classes.upgrade} onClick={() => { navigate('/ai_timesheet') }}>Upgrade</Box>
                                            </Grid>
                                            : ''}

                                    </Grid>
                                    <Grid item container lg={12} xs={12} spacing={1} pt={2}>
                                        <Grid item lg={8} xs={8}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Text black12 sx={{ fontWeight: '400px' }}>MiVi Chat Bot</Text>
                                                <Text largeOrange16 sx={{ marginTop: '-3px' }}>{planDetails.mivi?.free_credits} <span style={{ font: '12px Quicksand', fontWeight: `${600}`, color: '#262626' }}>{planDetails.mivi?.paid_credits != 0 ? `+ ${planDetails.mivi?.paid_credits}` : ''}</span></Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} xs={4} textAlign='end'>
                                            <Text mediumGreyHeader600>({planDetails.mivi?.used_credits}/{planDetails.mivi?.total_credits})</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <LinearProgressBundle variant="determinate" value={percentage(planDetails.mivi?.used_credits, planDetails.mivi?.available_credits)} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container lg={12} xs={12} spacing={1} pt={2}>
                                        <Grid item lg={8} xs={8}>
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                <Text black12 sx={{ fontWeight: '400px important' }}>AI Timesheets</Text>
                                                <Text largeOrange16 sx={{ marginTop: '-3px' }}>{planDetails.ai?.free_credits} <span style={{ font: '12px Quicksand', fontWeight: `${600}`, color: '#262626' }}>{planDetails.ai?.paid_credits != 0 ? `+ ${planDetails.ai?.paid_credits}` : ''}</span></Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} xs={4} textAlign='end'>
                                            <Text mediumGreyHeader600>({planDetails.ai?.used_credits}/{planDetails.ai?.total_credits}) </Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <LinearProgressBundle variant="determinate" value={percentage(planDetails.ai?.used_credits, planDetails.ai?.available_credit)} />
                                        </Grid>
                                        {
                                            planDetails.ai?.subscription_expired ?
                                                <Grid item lg={12} xs={12}>
                                                    <Text smallRed>Expired</Text>
                                                </Grid> : planDetails.ai?.subscription_expiring ?
                                                    <Grid item lg={12} xs={12}>
                                                        <Text smallRed>{`Expiring on ${planDetails.ai?.subscription_ends_on}`}</Text>
                                                    </Grid>
                                                    : ''
                                        }
                                    </Grid>
                                </Grid>
                        }

                    </Box>
                </Modal>
                {
                    // loader ?
                    //     < Skeleton animation='wave' width='100px' height='40px' /> :
                    LocalStorage.getUserData().super_admin && 
                    LocalStorage.getUserData().plan_name != null && LocalStorage.getUserData().org_config_set ?
                        <Hidden smDown>
                            <div id='planstatus' style={{padding:'3px'}}>
                            <Box  sx={{ 
                                borderRadius: '48px', backgroundImage: planDetails.subscription_expiring ? 'linear-gradient(135deg, #FEB692 0%, #EA5455 100%)' : (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'linear-gradient(105.13deg, #FFC910 48.23%, #FFDBA0 168.19%)' :
                                    (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly' ? 'linear-gradient(101.02deg, #0998FF 27.28%, #F9D4BC 163.38%)' : 'linear-gradient(92deg, #00A274 1.69%, #C9D754 148.11%)'), height: '34px', padding: '7px 7px 7px 7px', maxWidth: '150px', minWidth: '107px', color: '#FFFFFF', fontSize: '14px', fontWeight: '400', textAlign: 'center', cursor: 'pointer', overflow: 'hidden'
                            }} onClick={() => {
                                LocalStorage.setlocationPath(location.pathname)
                                navigate('/billing', {
                                    state: {
                                        renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                                            ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                                                (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                                        renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                                        autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                                        autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                                        autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
                                    }
                                })
                            }}>
                                {
                                    planDetails.subscription_expiring ?
                                        <div className="scrolling-text-container">
                                            <div className="scrolling-text">
                                                {planDetails.is_plan_auto_renewed ? 'Renewing soon' : 'Expiring Soon'}
                                            </div>
                                        </div> :
                                        planDetails.current_plan_slug == 'trail' ? 'Upgrade' : (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'Advanced' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'Starter' : ''
                                }
                            </Box></div>
                        </Hidden>
                        : ''
                }
                {location.pathname != "/ocr/bulk-upload-timesheets" && location.pathname != "/employee/verify" && <Notifications />}
                <AccountMenu person={props.profile_url ? props.profile_url : Profile} UserName={LocalStorage.getUserData().full_name} MailId={LocalStorage.getUserData().email_iD} />
            </div>
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={openPopup}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "50px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={offBoardSave} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Your progress will not be saved</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button smallBlackOutline onClick={() => { setOpenpopup(false); }} >
                                Cancel
                            </Button>
                            <Button smallBlue redBackground onClick={() => { navigate(url); setOpenpopup(false) }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            <Drawer open={openDrawer} onClose={() => { setopenDrawer(false) }}>
                <ul style={{ justifyContent: 'center', marginLeft: '10px' }}>
                    <Box p={2} pl={4}>
                        <img src={urlLogo} alt="Logo" style={{ height: "54px", width: '54px', borderRadius: '50%' }} />
                    </Box>
                    <li style={{ paddingBottom: '6px', textAlign: 'center' }} onClick={() => { setopenDrawer(false); navigate('/dashboard') }}>
                        <Box display='flex' flexDirection='row' gap={2} alignItems='center' sx={{
                            "&:hover": {
                                background: '#0C75EB'
                            },
                            maxWidth: openDrawer ? '160px' : '50px',
                            padding: '10px',
                            paddingRight: '20px',
                            borderRadius: "8px",
                        }}>
                            <img src={DashboardLogo} alt="dashboard" style={{ height: '28px', width: '28px' }} />
                            {
                                openDrawer ? <Text sx={{
                                    fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", "&:hover": {
                                        color: "#fff !important"
                                    }
                                }}>Dashboard</Text> : ''
                            }
                        </Box>
                    </li>
                    <li style={{ padding: '6px 8px' }}> <Divider /></li>
                    <li style={{ padding: '6px 0px' }} onClick={() => { setopenDrawer(false); navigate('/employees') }}>
                        <Box display='flex' flexDirection='row' gap={2} sx={{
                            "&:hover": {
                                background: '#0C75EB'
                            },
                            maxWidth: openDrawer ? '160px' : '50px',
                            padding: '10px',
                            borderRadius: "8px",
                        }}>
                            <img src={EmployeesLogo} alt="dashboard" style={{ height: '28px', width: '28px' }} />
                            {
                                openDrawer ? <Text sx={{
                                    fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", "&:hover": {
                                        color: "#fff !important"
                                    }
                                }}>Employees</Text> : ''
                            }
                        </Box>
                    </li>
                    <li style={{ padding: '6px 0px' }} onClick={() => { setopenDrawer(false); navigate('/placements') }}>
                        <Box display='flex' flexDirection='row' gap={2} sx={{
                            "&:hover": {
                                background: '#0C75EB'
                            },
                            maxWidth: openDrawer ? '160px' : '50px',
                            padding: '10px',
                            borderRadius: "8px",
                        }}>
                            <img src={PlacementLogo} alt="dashboard" style={{ height: '28px', width: '28px' }} />
                            {
                                openDrawer ? <Text sx={{
                                    fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", "&:hover": {
                                        color: "#fff !important"
                                    }
                                }}>Placements</Text> : ''
                            }
                        </Box>
                    </li>
                    <li style={{ padding: '6px 0px' }} onClick={() => { setopenDrawer(false); navigate('/timesheet') }}>
                        <Box display='flex' flexDirection='row' gap={2} sx={{
                            "&:hover": {
                                background: '#0C75EB'
                            },
                            maxWidth: openDrawer ? '160px' : '50px',
                            padding: '10px',
                            borderRadius: "8px",
                        }}>
                            <img src={TimeSheetLogo} alt="dashboard" style={{ height: '28px', width: '28px' }} />
                            {
                                openDrawer ? <Text sx={{
                                    fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", "&:hover": {
                                        color: "#fff !important"
                                    }
                                }}>Timesheets</Text> : ''
                            }
                        </Box>
                    </li>
                    <li style={{ padding: '6px 8px' }}> <Divider /></li>
                    <li style={{ padding: '6px 0px' }} onClick={() => { setopenDrawer(false); navigate('/clients') }}>
                        <Box display='flex' flexDirection='row' gap={2} sx={{
                            "&:hover": {
                                background: '#0C75EB'
                            },
                            maxWidth: openDrawer ? '160px' : '50px',
                            padding: '10px',
                            borderRadius: "8px",
                        }}>
                            <img src={clientLogo} alt="dashboard" style={{ height: '28px', width: '28px' }} />
                            {
                                openDrawer ? <Text sx={{
                                    fontSize: "15px", fontFamily: "Quicksand", fontWeight: "500", "&:hover": {
                                        color: "#fff !important"
                                    }
                                }}>Clients</Text> : ''
                            }
                        </Box>
                    </li>
                    {/* {
                        LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true)) ?
                            <li style={{ backgroundColor: "#FFFFFF", display: 'flex', position: "absolute", bottom: openDrawer.open ? 70 : 50 }}>
                                {
                                    openDrawer.open ?
                                        <Box sx={{ width: '100%', paddingRight: '8px' }}>
                                            {
                                                (planDetails.prompt_payment_slug == '' && planDetails.ai_ts_payment_slug == '') ?
                                                    <Box justifyContent='center' textAlign='center' sx={{
                                                        backgroundImage: `url(${credits})`,
                                                        // height: '127px',
                                                        width: '244.5px !important',
                                                        // margin:'10px 0px'
                                                    }}>
                                                        <Grid container xs={12} justifyContent='center' textAlign='center' spacing={1} p={'45px 0px 0px 0px'}>
                                                            <Grid item xs={12}>
                                                                <Text largeWhite>Explore AI Bundles!</Text>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Text mediumWhite400>Looking for additional credits to buy<br /> Mivi AI prompts or AI Timesheets?</Text>
                                                            </Grid>
                                                            <Grid item xs={8} justifyContent='center' py={3}>
                                                                <Button viewPlans onClick={() => { setopenDrawer({ ...openDrawer, open: false }); navigate('/ai_timesheet') }}>View plans</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box> :
                                                    <Grid container sx={{ border: '1px solid #007050', padding: '14px 12px 24px 12px', width: '100%', borderRadius: '16px' }}>
                                                        <Grid item container lg={12} xs={12} spacing={2} pr={1}>
                                                            <Grid item lg={1} xs={2}>
                                                                <img src={star} alt="star" />
                                                            </Grid>
                                                            <Grid item lg={8} xs={8}>
                                                                <Text black12>AI Bundles</Text>
                                                            </Grid>
                                                            {
                                                                (planDetails.ai_timesheet_access_paid_credits == 0 && planDetails.prompt_access_paid_credits == 0) ? '' :
                                                                    <Grid item lg={2} xs={2} textAlign='center'>
                                                                        <Text greensmall sx={{ cursor: 'pointer' }} onClick={() => { setopenDrawer({ ...openDrawer, open: false }); navigate('/ai_timesheet') }}>Upgrade</Text>
                                                                    </Grid>
                                                            }
                                                        </Grid>
                                                        <Grid item container lg={12} xs={12} spacing={1} pt={2}>
                                                            <Grid item lg={6} xs={6}>
                                                                <Text black12 sx={{ fontWeight: '400px' }}>MiVi Chat Bot</Text>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6} textAlign='end'>
                                                                <Text smallGrey400>{planDetails.prompt_access_paid_credits} Left</Text>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                <LinearProgressBundle variant="determinate" value={percentage(planDetails.prompt_usedCredits, planDetails.prompt_access_credits)} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container lg={12} xs={12} spacing={1} pt={2}>
                                                            <Grid item lg={6} xs={6}>
                                                                <Text black12 sx={{ fontWeight: '400px important' }}>AI Timesheets</Text>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6} textAlign='end'>
                                                                <Text smallGrey400>{planDetails.ai_timesheet_access_paid_credits} Left</Text>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                <LinearProgressBundle variant="determinate" value={percentage(planDetails.ai_usedCredits, planDetails.ai_ts_access_credits)} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </Box>
                                        :
                                        <Grid container lg={7} xs={7} justifyContent='center'>
                                            <img src={bundlelogo} alt="logo" style={{ cursor: 'pointer' }} onClick={() => setopenDrawer({ ...openDrawer, open: true })} />
                                        </Grid>
                                }
                            </li> : ''
                    } */}
                    {/* <Box sx={{ display: 'flex', position: 'fixed', bottom: 10, flexDirection: 'row' }}>
                        <img src={logo} alt="logo" style={{ height: "40px", width: "40px" }} />
                        <Text verySmallBlack style={{ margin: "0px 8px" }}>
                            Powered by Codetru <br />All Rights Reserved<br />
                            {app_version}
                        </Text>
                    </Box> */}
                </ul>
            </Drawer>

        </Box >
    )
};
