import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import Text from '../../../../../components/customText/Text'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAccordion from '../../../../../components/accordion/CustomAccordion';
import { useState } from 'react';
import LocalStorage from '../../../../../utils/LocalStorage';
import Input from '../../../../../components/input/Input';
import DocumentStyles from './DocumentStyles';
import CommonApi from '../../../../../apis/CommonApi';
import { useEffect } from 'react';
import Button from '../../../../../components/customButton/Button';
import { isValid, onCharactersAndNumbersChange, validate_emptyField, validate_passport, validates_new_i94 } from '../../../../../components/Validation';
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, dateFormat, findKeysWithEmptyValues, getCountryDetails, openDocumentInNewtab, removeLoader } from '../../../../../utils/utils';
import editIcon from '../../../../../assets/svg/square-edit.svg';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import EducationalDetails from './EducationalDetails';
import PersonalDocuments from './PersonalDocuments';
import Date from '../../../../../components/datePicker/Date';
import moment from 'moment';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import draft from '../../../../../assets/employee/savedraft.svg';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import { useLocation, useNavigate } from 'react-router-dom';// eslint-disable-next-line
import EmployeeCreateAPI from '../../../../../apis/admin/employees/EmployeesApi';
import LoaderIcon from '../../../../../assets/gif/japfuLoader.gif';
import { blue } from '../../../../../theme';
import successImg from '../../../../../assets/svg/succesIcon.svg';
import Dropzone from 'react-dropzone';
import download from '../../../../../assets/employee/filedownload.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import Select from '../../../../../components/select/Select';
import LoadingButton from '../../../../../components/customButton/LoadingButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const expiryTypeList = [
    {
        id: 2,
        value: "Date"
    },
    {
        id: 1,
        value: "D/S"
    }
]

function Documents({ docStepper, setDocStepper, mainStep, setMainStep, setSubstepper, value, setValue, handleBack, inviteData, setInviteData }) {
    const location = useLocation()
    const emp_id = LocalStorage.getEmployeeId()
    const navigate = useNavigate();
    const classes = DocumentStyles();// eslint-disable-next-line
    const [deleteDocsEnable, setDeleteDocsEnable] = useState(false);// eslint-disable-next-line    
    const [countries, setCountries] = useState(getCountryDetails() ? getCountryDetails() : []);
    const [error, setError] = useState({ document_number: '' });
    const [i94Error, setI94Error] = useState({ document_number: '' });
    const [visaError, setVisaError] = useState({});
    const [visaTypes, setVisaTypes] = useState([]);
    const theme = useTheme();
    const [deleteVisafiles, setDeleteVisafiles] = useState([]);
    const [expand, setExpand] = useState(false);
    const [i94expand, setI94Expand] = useState(false);
    const [visaexpand, setVisaexpand] = useState(false);
    const [open, setOpen] = useState(false);
    const [saveBtn, setSaveBtn] = useState(true);
    const [i9SaveBtn, setI9SaveBtn] = useState(true);
    const [visaSaveBtn, setVisaSaveBtn] = useState(true);
    const [editState, setEditState] = useState('');
    const [I94EditState, setI94EditState] = useState('');
    const [visaEditState, setVisaEditState] = useState('');// eslint-disable-next-line
    const [clearAll, setClearAll] = useState(false);
    const [i94ClearAll, setI94ClearAll] = useState(false);
    const [visaClearAll, setVisaClearAll] = useState(false);
    const [disable, setDisable] = useState(0);// eslint-disable-next-line
    const [formLoader, setFormLoader] = useState(false);// eslint-disable-next-line
    const [I94FormLoader, setI94FormLoader] = useState(false);// eslint-disable-next-line
    const [visaLoader, setVisaLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [clearItem, setClearItem] = useState('');
    const [clearAlert, setClearAlert] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [passportClearAll, setPassportClearAll] = useState(false);
    const [defaultOpen, setdefaultOpen] = useState(false)
    const [passport, setPassport] = useState({
        id: '',
        issued_country_id: '',
        valid_from: '',
        valid_till: '',
        document_number: '',
        documents: [
            {
                name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ""
            }
        ]
    })

    const [i94, setI94] = useState({
        id: '',
        valid_from: '',
        valid_till: '',
        document_number: '',
        country_id: '',
        documents: [
            {
                name: '',
                id: '',
                new_document_id: '',
                document_url: '',
                slug: ""
            }
        ]
    })

    const [visa, setVisa] = useState({
        visa_type_id: LocalStorage.getVisaID() ? parseInt(LocalStorage.getVisaID()) : '', // eslint-disable-next-line
        id: '',
        valid_from: '',
        valid_till: '',
        support_documents: [],
        support_documents_deleted_ids: []
    })

    useEffect(() => {
        if (docStepper == 1) {  // Ensures API calls only happen when `docStepper` has a value or meets a condition
            VisaList();
            PassIndexApi(emp_id);
            i94IndexApi(emp_id);
            visaIndexApi(emp_id);
            setdefaultOpen(false)
        }
        // eslint-disable-next-line
    }, [docStepper]);  // Will run only when `docStepper` changes

    const handleFormStates = (param, param1) => {
        for (const key in param.upload_documents) {
            const dataArray = param.upload_documents[key].data;
            if (dataArray.length >= 1) {
                const item = dataArray[0];
                if (param1 == "i94" && item.name === "i94" && item.approval_status == "Approved") {
                    setI94({
                        ...i94,
                        id: "",
                        valid_from: item.date_of_issue ? item.date_of_issue : item.valid_from,
                        expiry_type: item.expiry_type,
                        valid_till: item.date_of_expiry ? item.date_of_expiry : item.valid_till,
                        document_number: item.document_number,
                        country_id: item.issued_country,
                        documents: [
                            {
                                name: item.document_name,
                                new_document_id: item.id,
                                document_slug: item.name,
                                slug: item.slug,
                                document_url: item.document_url
                            }
                        ]
                    })
                } else if (param1 == "passport" && item.name == "passport" && item.approval_status == "Approved") {
                    setPassport({
                        ...passport,
                        id: "",
                        issued_country_id: item.issued_country,
                        valid_from: item.date_of_issue,
                        valid_till: item.date_of_expiry,
                        document_number: item.document_number,
                        documents: [
                            {
                                name: item.document_name,
                                document_slug: item.name,
                                slug: item.slug,
                                new_document_id: item.id,
                                document_url: item.document_url
                            }
                        ]
                    })
                } else if (param1 == "work_authorization" && item.name == "work_authorization") {
                    visa.id = "";
                    visa.visa_type_id = LocalStorage.getVisaID() ? parseInt(LocalStorage.getVisaID()) : handleVisaRetrun(dataArray, "visa_type_id");
                    visa.valid_from = handleVisaRetrun(dataArray, "valid_from");
                    visa.valid_till = handleVisaRetrun(dataArray, "valid_till");
                    visa.support_documents = handleReturnArray(dataArray)
                    setVisa({ ...visa })
                }
            }
        }
    }

    const handleVisaRetrun = (param, name) => {
        const approved = param.filter(doc => doc.approval_status === 'Approved');
        if (approved.length > 0) {
            if (name === "visa_type_id") {
                return approved[0].visa_type_id;
            } else if (name === "valid_from") {
                return approved[0].date_of_issue;
            } else if (name == "valid_till") {
                return approved[0].date_of_expiry;
            }
        }
    }

    const handleReturnArray = (param) => {
        var empArr = []
        for (var i in param) {
            if (param[i].approval_status == "Approved") {
                empArr.push({
                    document_url: param[i].document_url,
                    slug: "invite_via_link",
                    document_slug: param[i].name,
                    document_name: param[i].document_name,
                    visa_document_upload_id: param[i].id,
                    id: "",
                })
            }
        }
        return empArr;
    }

    useEffect(() => {
        if (passport.id !== '' && i94.id !== '' && visa.id !== '') {
            setDisable(3)
        }
        // eslint-disable-next-line
    }, [passport, i94, visa])

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const deleteDoc = (index, args) => {
        if (args == 'passport') {
            if (passport.documents[index].id !== "") {
                passport['documents_deleted_ids'] = [passport.documents[0].id];
            }
            if (inviteData != null) {
                passport.documents[index].document_slug = '';
            }
            passport.documents[index].slug = '';
            passport.documents[index].new_document_id = '';
            passport.documents[index].name = '';
            passport.documents[index].document_url = '';
            passport.documents[index].id = '';
            setPassport({ ...passport });
            if (passport.document_number === "" && passport.issued_country_id == "" && passport.valid_from == "" && passport.valid_till == "" && passport.documents[0].document_url == "") {
                error['document_number'] = "";
                setError({ ...error })
            }
            // }
        } else if (args == 'i94') {
            if (i94.documents[index].id !== "") {
                i94['documents_deleted_ids'] = [i94.documents[0].id];
            }
            if (inviteData != null) {

                i94.documents[index].document_slug = '';
            }
            i94.documents[index].slug = '';
            i94.documents[index].new_document_id = ''
            i94.documents[index].name = ''
            i94.documents[index].id = '';
            i94.documents[index].document_url = '';
            setI94({ ...i94 });
            if (i94.document_number === "" && i94.country_id == "" && i94.valid_from == "" && i94.valid_till == "" && i94.documents[0].document_url == "") {
                i94Error['document_number'] = "";
                setI94Error({ ...i94Error })
            }

        } else if (args == 'visa') {
            handleVisaDelete(index);
        }

        setDeleteOpen(false);
        setDeleteIndex(null);
        setDeleteItem(null);
    }

    const handleVisaChange = (e) => {
        visa[e.target.name] = e.target.value;
        setVisa({ ...visa });
        handleValidateVisa(e);
    }

    const changeHandler = (e, index, args, visaargs) => {
        setdefaultOpen(false)
        if (args == 'passport') {
            if (e.target.name == 'new_document_id') {
                uploadDocs(e, 'passport', '', '', 'passport-document');
            }
            else {
                passport[e.target.name] = e.target.value;
                setPassport({ ...passport })
            }
            handleValidate(e);
            if (passport.document_number === "" && passport.issued_country_id == "" && passport.valid_from == "" && passport.valid_till == "" && passport.documents[0].document_url == "") {
                error['document_number'] = "";
                setError({ ...error })
            }

        } else if (args == 'i94') {
            if (e.target.name == 'expiry_type') {
                i94.valid_till = ''
                if (e.target.value == 2) {
                    setdefaultOpen(true)
                }
            }
            if (e.target.name == 'new_document_id') {
                uploadDocs(e, 'i94', '', '', 'i94-document');
            } else {
                i94[e.target.name] = e.target.value;
                setI94({ ...i94 });
                handleValidateI94(e);
            }
            if (i94.document_number === "" && i94.country_id == "" && i94.valid_from == "" && i94.valid_till == "" && i94.documents[0].document_url == "") {
                i94Error['document_number'] = "";
                setI94Error({ ...i94Error })
            }

        }
        // else if (args == 'visa') {
        //     if (e.target.name == 'visa_type_id') {
        //         visa[e.target.name] = e.target.value;
        //         setVisa({ ...visa })
        //     } else if (e.target.name == 'new_document_id' || e.target.name == 'visa_document_upload_id') {
        //         uploadDocs(e, 'visa', visaargs, index, 'visa-document');
        //     }
        //     else if (visaargs == 'suppMul') {
        //         visa.support_documents[index][e.target.name] = e.target.value
        //         setVisa({ ...visa });
        //     } else {
        //         visa[e.target.name] = e.target.value;
        //         setVisa({ ...visa })
        //     }
        // }
        // else {
        //     visa[e.target.name] = e.target.value;
        //     setVisa({ ...visa })
        // }

        // handleValidateVisa(e);
    }

    const dateChange = (e, name, args) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (args == 'passport') {
            setPassport({
                ...passport,
                [name]: date == '' ? '' : moment(date).format(dateFormat())
            }, handleValidate(event))
        } else if (args == 'i94') {
            if (name == 'valid_till') {
                setdefaultOpen(false)
                i94Error.valid_till = ''
                setI94Error({ ...i94Error })
            }
            setI94({
                ...i94,
                [name]: date == '' ? '' : moment(date).format(dateFormat())
            }, handleValidateI94(event))
        } else if (args == 'visa') {
            setVisa({
                ...visa,
                [name]: date == '' ? '' : moment(date).format(dateFormat())
            }, handleValidateVisa(event))
        }
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case 'document_number':
                error.document_number = input.value !== '' ? validate_passport(input.value) : (input.value === '' && (passport.issued_country_id !== "" || passport.valid_from !== "" || passport.valid_till !== "" || passport.documents[0].document_url !== "")) ? validate_emptyField(input.value) : ""
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const handleValidateI94 = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case 'document_number':
                i94Error.document_number = input.value !== '' ? validates_new_i94(input.value) : (input.value === '' && (i94.country_id !== "" || i94.valid_from !== "" || i94.valid_till !== "" || i94.documents[0].document_url !== "")) ? validate_emptyField(input.value) : ""
                break;

            default:
                break;
        }
        setI94Error({ ...i94Error });
    }

    const handleValidateVisa = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case 'visa_type_id':
                visaError.visa_type_id = input.value !== '' ? validate_emptyField(input.value) : input.value == "" && (visa.support_documents.length !== 0 || visa.valid_from !== "" || visa.valid_till !== "") ? validate_emptyField(input.value) : "";
                break;
            default:
                break;
        }
        setVisaError({ ...visaError });
    }

    const [passportLoader, setPassportLoader] = useState(false);
    const handleSubmit = async () => {
        passport['employee_id'] = emp_id;
        passport['request_id'] = LocalStorage.uid();
        if (passport.document_number !== "" && passport.document_number.length < 8) {
            addErrorMsg("Passport Number must be between 8 and 16 characters");
            return true;
        }
        if (passport.document_number === "" && (passport.issued_country_id !== "" || passport.valid_from !== "" || passport.valid_till !== "" || passport.documents[0].document_url !== "")) {
            error['document_number'] = "This field is required";
            setError({ ...error });
            addErrorMsg("Passport Number is required");
            return true;
        }
        try {
            if (passport.id !== '') {
                if (passportClearAll || (passport.document_number === "" && passport.issued_country_id == "" && passport.valid_from == "" && passport.valid_till == "" && passport.documents[0].document_url == "")) {
                    var deleteObj = {
                        request_id: LocalStorage.uid(),
                        employee_id: emp_id,
                        id: passport.id
                    }
                    setPassportLoader(true);
                    const res = await EmployeeCreateAPI.deletePassport(deleteObj, passport.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        passport.id = "";
                        setPassport({ ...passport });
                        addSuccessMsg(`Passport Documents Updated Successfully`);
                        setExpand(!expand);
                        setSaveBtn(false);
                        PassIndexApi(emp_id);
                    } else {
                        addErrorMsg(res.data.message);
                    }
                } else {
                    setPassportLoader(true);
                    const res = await OnBoardApi.passportUpdate(passport, passport.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Passport Document Updated Successfully`);
                        setExpand(!expand);
                        if (editState == 'edit') {
                            setEditState('view');
                        }
                        setSaveBtn(false);
                        PassIndexApi(emp_id);
                    } else {
                        if (res.data.message === "Document Number already exists") {
                            error['document_number'] = "Document Number already exists";
                            setError({ ...error });
                            setExpand(!expand);
                        } else {
                            addErrorMsg(res.data.message);
                        }

                    }
                }
            } else {
                if (passport.document_number !== "") {
                    setPassportLoader(true);
                    const res = await OnBoardApi.passportStore(passport, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Passport Documents Added Successfully`);
                        setDisable(disable + 1);
                        setSaveBtn(false);
                        setEditState('view');
                        if (editState == 'view') {
                            setExpand(expand);
                        } else {
                            setExpand(!expand);
                        }
                        if (inviteData != null) {
                            inviteData.upload_documents.passport.data = [];
                            setInviteData({ ...inviteData });
                        }
                        PassIndexApi(emp_id);
                    } else {
                        if (res.data.message === "Document Number already exists") {
                            error['document_number'] = "Document Number already exists";
                            setError({ ...error });
                            setExpand(true);
                        }
                        addErrorMsg(res.data.message);
                        return true;
                    }
                } else {
                    setExpand(!expand);
                }
            }
        } catch (error) {
            addErrorMsg('An error occurred while saving the passport data. Please try again.');
        } finally {
            setPassportLoader(false);
        }
    }
    const [iloader, setIloader] = useState(false);
    const handleI94Submit = async () => {
        i94['request_id'] = LocalStorage.uid();
        i94['employee_id'] = emp_id;
        if (i94.document_number !== "" && i94.document_number.length < 11) {
            addErrorMsg("i94 Number must be 11 characters in length");
            return true;
        }
        if (validates_new_i94(i94.document_number)) {
            i94Error['document_number'] = validates_new_i94(i94.document_number);
            setI94Error({ ...i94Error });
            addErrorMsg(validates_new_i94(i94.document_number));
            return true;
        }
        if (i94.document_number === "" && (i94.country_id !== "" || i94.valid_from !== "" || i94.valid_till !== "" || i94.documents[0].document_url !== "")) {
            i94Error['document_number'] = 'This field is required';
            setI94Error({ ...i94Error });
            addErrorMsg("I94 Number is required");
            return true;
        }
        if (i94.expiry_type == 2) {
            if (i94.valid_till == '') {
                i94Error.valid_till = 'This field is required';
                setI94Error({ ...i94Error });
                addErrorMsg("Admit until date is required");
                return true;
            }
        }
        try {
            if (i94.id !== '') {
                if (i94ClearAll || (i94.document_number === "" && i94.country_id == "" && i94.valid_from == "" && i94.valid_till == "" && i94.documents[0].document_url == "")) {
                    var deleteObj = {
                        request_id: LocalStorage.uid(),
                        employee_id: emp_id,
                        id: i94.id
                    }
                    setIloader(true);
                    const res = await EmployeeCreateAPI.deleteI94(deleteObj, i94.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        i94.id = "";
                        setI94({ ...i94 });
                        addSuccessMsg(`I-94 Documents Updated Successfully`);
                        setI94Expand(!i94expand);
                        setI9SaveBtn(true);
                        i94IndexApi(LocalStorage.getEmployeeId());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                } else {
                    setIloader(true);
                    const res = await OnBoardApi.i94Update(i94, i94.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`I-94 Documents Updated Successfully`);
                        setI94Expand(!i94expand);
                        if (I94EditState == 'edit') {
                            setI94EditState('view');
                        }
                        setI94EditState('view');
                        setI9SaveBtn(false);
                        i94IndexApi(LocalStorage.getEmployeeId());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                }
            } else {
                if (i94.document_number !== "") {
                    setIloader(true);
                    const res = await OnBoardApi.i94Store(i94, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`I-94 Documents Added Successfully`);
                        setI94Expand(!i94expand);
                        setDisable(disable + 1);
                        i94IndexApi(LocalStorage.getEmployeeId());
                        setI94EditState('view');
                        if (i94.id !== '') {
                            setI94EditState('view');
                        }
                        if (I94EditState == 'view') {
                            setI94Expand(i94expand);
                        } else {
                            setI94Expand(!i94expand);
                        }
                        if (inviteData != null) {
                            inviteData.upload_documents.i94.data = [];
                            setInviteData({ ...inviteData });
                        }
                        setI9SaveBtn(false);
                    } else {
                        addErrorMsg(res.data.message);
                    }
                } else {
                    setI94Expand(!i94expand);
                }
            }
        } catch (error) {
            addErrorMsg('An error occurred while saving the I-94 data. Please try again.');
        } finally {
            setIloader(false);
        }
    }
    const [visaLoaderBtn, setVisaLoaderBtn] = useState(false);
    const handleVisaSubmit = async () => {
        visa['request_id'] = LocalStorage.uid();
        visa['employee_id'] = emp_id;
        if (visa.visa_type_id == "" && (visa.support_documents.length !== 0 || visa.valid_from !== "" || visa.valid_till !== "")) {
            visaError['visa_type_id'] = "This field is required";
            setVisaError({ ...visaError });
            addErrorMsg("Visa Type is required");
            return true;
        }
        try {
            if (visa.id !== '') {
                if (visaClearAll || (visa.support_documents.length == 0 && visa.visa_type_id == "" && visa.valid_from == "" && visa.valid_till == "")) {
                    var deleteObj = {
                        request_id: LocalStorage.uid(),
                        employee_id: emp_id,
                        id: visa.id
                    }
                    setVisaLoaderBtn(true);
                    const res = await EmployeeCreateAPI.deleteVisa(deleteObj, visa.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        visa.id = "";
                        setVisa({ ...visa });
                        addSuccessMsg(`Visa Documents Updated Successfully`);
                        setVisaexpand(!visaexpand);
                        setVisaSaveBtn(false);
                        visaIndexApi(LocalStorage.getEmployeeId());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                } else {
                    setVisaLoaderBtn(true);
                    const res = await OnBoardApi.visaUpdate(visa, visa.id, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        addSuccessMsg(`Visa Documents Updated Successfully`);
                        setVisaexpand(!visaexpand);
                        setDisable(disable + 1);
                        if (visaEditState == 'edit') {
                            setVisaEditState('view');
                        }
                        setVisaEditState('view');
                        setVisaSaveBtn(false);
                        visaIndexApi(LocalStorage.getEmployeeId());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                }
            } else {
                if (visa.support_documents.length == 0 && visa.visa_type_id == "" && visa.valid_from == "" && visa.valid_till == "") {
                    setVisaexpand(!visaexpand);
                } else {
                    setVisaLoaderBtn(true);
                    const res = await OnBoardApi.visaStore(visa, LocalStorage.getAccessToken());
                    if (res.data.statusCode === 1003) {
                        if (inviteData != null) {
                            inviteData.upload_documents.work_authorization.data = [];
                            setInviteData({ ...inviteData });
                        }
                        addSuccessMsg(`Visa Documents Added Successfully`);
                        setVisaexpand(!visaexpand);
                        setDisable(disable + 1);
                        setVisaEditState('view');
                        if (visaEditState == 'view') {
                            setVisaexpand(visaexpand);
                        } else {
                            setVisaexpand(!visaexpand);
                        }
                        setVisaEditState('view');
                        visaIndexApi(LocalStorage.getEmployeeId());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                }
            }
        } catch (error) {
            addErrorMsg('An error occurred while saving the visa data. Please try again.');
        } finally {
            setVisaLoaderBtn(false);
        }
    }

    const nextStep = async () => {
        try {
            if (i94Error.document_number == '' && error.document_number == '') {
                if (passport.document_number != '' || passportClearAll) {
                    await handleSubmit();
                }
                if (i94.document_number != '' || i94ClearAll) {
                    await handleI94Submit();
                }
                if (visa.visa_type_id != '' || visaClearAll) {
                    await handleVisaSubmit();
                }
                if (!isValid(error)) {
                    addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                } else {
                    setExpand(false);
                    setVisaexpand(false);
                    setI94Expand(false);
                    setDocStepper(docStepper + 1);
                    setValue(value + 1);
                }

            } else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        } catch (error) {
            addErrorMsg('An error occurred while saving the data. Please try again.');
        }
    }
    const workAuthBack = () => {
        handleBack();
        // setValueMain(0);
        setValue(4)
    }

    const uploadDocs = (value, args, visaargs, index, slug_name) => {
        const formData = new FormData();
        formData.append('files', value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (value.target.files[0].type == "image/png" || value.target.files[0].type == "image/jpeg" || value.target.files[0].type == "application/jpeg" || value.target.files[0].type == "application/png" || value.target.files[0].type == "application/jpg" || value.target.files[0].type == "application/pdf") {
            let size = ((value.target.files[0].size / (1024 * 1024)).toFixed(2))
            if (Number(size) <= 25) {
                addLoader();
                CommonApi.documentUpload(slug_name, formData, LocalStorage.getAccessToken()).then((response) => {
                    removeLoader()
                    if (response.data.statusCode == 1003) {
                        if (args == 'i94') {
                            i94.documents[0].document_url = response.data.data.document_url ? response.data.data.document_url : ""
                            i94.documents[0].new_document_id = response.data.data.id ? response.data.data.id : ""
                            i94.documents[0].name = value.target.files[0].name;
                            addLoader()
                            const data = {
                                id: response.data.data.id,
                                url: response.data.data.document_url
                            }
                            CommonApi.ocrDocumentUpload('i94-data', data).then((res) => {
                                if (res.data.statusCode === 1003) {
                                    removeLoader()
                                    const admitUntilDate = res?.data?.data?.admit_until_date.toLowerCase();
                                    i94.document_number = res.data.data.i94_record_number ? res.data.data.i94_record_number : res.data.data.i_94_record_number ? res.data.data.i94_record_number : i94.document_number
                                    i94.valid_from = moment(res?.data?.data?.recent_date_of_entry, dateFormat()).format(dateFormat())
                                    i94.valid_till = res.data.data.admit_until_date ? admitUntilDate != 'd/s' ? moment(res.data.data.admit_until_date, dateFormat()).format(dateFormat()) : i94.valid_till : i94.valid_till
                                    i94.expiry_type = res.data.data.admit_until_date ? admitUntilDate == 'd/s' ? 1 : 2 : i94.expiry_type
                                    setI94({ ...i94 })
                                }
                                else {
                                    removeLoader()
                                }
                            })
                            i94.documents[0][`slug`] = "";
                            setI94({ ...i94 })
                            // setI94FormLoader(true);
                            // if (response.data.statusCode == 1003) {
                            //     let deleteArr = deleteI94files;
                            //     i94.documents[0].document_url = response.data.data.document_url
                            //     const data = {
                            //         request_id: LocalStorage.uid(),
                            //         document_id: response.data.data.id,
                            //     }
                            //     EmployeeCreateAPI.ocrDocumentUpload('i94', data).then((res) => {
                            //         if (res.data.statusCode == 1003) {
                            //             // setI94FormLoader(false);
                            //             removeLoader()
                            //             i94.document_number = res.data.data.document_number ? res.data.data.document_number : i94.document_number
                            //             i94.documents[0].new_document_id = response.data.data.id ? response.data.data.id : i94.documents[0].new_document_id
                            //             i94.documents[0].name = value.target.files[0].name
                            //         } else {
                            //             removeLoader()
                            //             // setI94FormLoader(false);
                            //             i94.documents[0].new_document_id = response.data.data.id ? response.data.data.id : i94.documents[0].new_document_id
                            //             i94.documents[0].name = value.target.files[0].name
                            //         }
                            //         setDeleteI94files([...deleteArr])
                            //         setI94({ ...i94 });
                            //     })
                            // }
                            // else {
                            //     addErrorMsg(response.data.message);
                            //     removeLoader()
                            //     // setI94FormLoader(false);
                            // }
                        } else if (args == 'visa') {
                            // setVisaLoader(true);
                            if (visaargs == 'suppMul') {
                                if (response.data.statusCode == 1003) {
                                    let deleteArr = deleteVisafiles;
                                    visa.support_documents[index].visa_document_type_name = value.target.files[0].name;
                                    visa.support_documents[index].supporting_documents_url = response.data.data.document_url;
                                    visa.support_documents[index].visa_document_upload_id = response.data.data.id ? response.data.data.id : visa.support_documents[index].visa_document_upload_id;
                                    setDeleteVisafiles([...deleteArr])
                                    setVisa({ ...visa });
                                    removeLoader()
                                    // setVisaLoader(false);
                                }
                                else {
                                    addErrorMsg(response.data.message);
                                    removeLoader()
                                    // setVisaLoader(false);
                                }
                            }
                        }
                        else {

                            addLoader()
                            passport.documents[0].document_url = response.data.data.document_url ? response.data.data.document_url : ""
                            passport.documents[0].new_document_id = response.data.data.id ? response.data.data.id : ""
                            passport.documents[0].name = response.data.data.document_name ? response.data.data.document_name : ""
                            const data = {
                                id: response.data.data.id,
                                url: response.data.data.document_url
                            }
                            CommonApi.ocrDocumentUpload('passport-data', data).then((res) => {
                                if (res.data.statusCode === 1003) {
                                    removeLoader()
                                    passport.document_number = res.data.data.passport_number ? res.data.data.passport_number : passport.document_number
                                    passport.valid_from = res.data.data.date_of_issue ? moment(res.data.data.date_of_issue, dateFormat()).format(dateFormat()) : passport.valid_from
                                    passport.valid_till = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, dateFormat()).format(dateFormat()) : passport.valid_till
                                    setPassport({ ...passport });
                                }
                                else {
                                    removeLoader()
                                }
                            })
                            setPassport({ ...passport });
                        }

                    } else {
                        removeLoader()
                        addErrorMsg(response.data.message);
                    }
                });
            } else {
                removeLoader()
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg(`Please upload files in PDF,PNG OR JPEG only`);
        }
    }

    const visaSupportingDocsUpload = (files) => {
        if (files.length < 11) {

            addLoader()
            for (const key in files) {
                var formData = new FormData();
                formData.append("files", files[key]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("visa-document", formData, LocalStorage.getAccessToken()).then((response) => {
                    removeLoader()
                    if (response.data.statusCode == 1003) {
                        var newDocs = visa.support_documents;
                        var obj = {
                            visa_document_upload_id: response.data.data.id,
                            document_url: response.data.data.document_url,
                            document_name: response.data.data.document_name,
                            slug: ""
                        }
                        newDocs.push(obj);
                        setVisa({ ...visa, support_documents: newDocs });
                        removeLoader()
                    } else {
                        removeLoader()
                        addErrorMsg(response.data.message);
                    }
                });
            }

        } else {
            addErrorMsg('You can upload a maximum of 10 files at a time.')
        }
    }

    const VisaList = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaTypes(res.data.data);
            }
        })
    }

    const PassIndexApi = (id) => {
        setFormLoader(true);
        // setError({ document_number: '' })
        OnBoardApi.passportIndex(LocalStorage.uid(), id, LocalStorage.getAccessToken()).then((res) => {
            setFormLoader(false);
            if (res.data.statusCode === 1003) {
                setPassportClearAll(false);
                if (res.data.data.length > 0) {
                    setEditState('view')
                    let data = res.data.data[0]
                    if (data.documents.length === 0) {
                        data.documents = [
                            {
                                name: '',
                                id: '',
                                new_document_id: '',
                                document_url: '',
                                slug: ""
                            }
                        ]
                        setPassport({ ...data })
                    }
                    else {
                        setPassport(data);
                    }
                } else {
                    setEditState('new');
                    setSaveBtn(true);
                    if (inviteData != null) {
                        handleFormStates(inviteData, "passport")
                    } else {
                        if (res.data.data.length === 0) {
                            setPassport({
                                ...passport
                                // id: '',
                                // issued_country_id: '',
                                // valid_from: '',
                                // valid_till: '',
                                // document_number: '',
                                // documents: [
                                //     {
                                //         name: '',
                                //         id: '',
                                //         new_document_id: '',
                                //         document_url: '',
                                //         slug: ""
                                //     }
                                // ]
                            })
                        }
                    }
                }
                setError({ document_number: '' })
            }
        })
    }

    const visaIndexApi = (id) => {
        setVisaLoader(true);
        // setVisaError({})
        OnBoardApi.visaIndex(LocalStorage.uid(), id, LocalStorage.getAccessToken()).then((res) => {
            setVisaLoader(false);
            if (res.data.statusCode === 1003) {
                setVisaClearAll(false);
                if (res.data.data.length > 0) {
                    setVisaEditState('view');
                    res.data.data[0].support_documents_deleted_ids = [];
                    setVisa({ ...res.data.data[0] });
                } else {
                    setVisaEditState('new');
                    setVisaSaveBtn(true);
                    if (inviteData != null) {
                        handleFormStates(inviteData, "work_authorization");
                    } else {
                        if (res.data.data.length === 0) {
                            setVisa({
                                visa_type_id: LocalStorage.getVisaID() ? parseInt(LocalStorage.getVisaID()) : '', // eslint-disable-next-line
                                // visaData: null,
                                ...visa
                                // id: '',
                                // valid_from: '',
                                // valid_till: '',
                                // support_documents: []
                            });
                        }
                    }
                }
            }
        })
    }

    const i94IndexApi = (id) => {
        setI94FormLoader(true);
        // setI94Error({ document_number: '' })
        OnBoardApi.I94Index(LocalStorage.uid(), id, LocalStorage.getAccessToken()).then((res) => {
            setI94FormLoader(false);
            if (res.data.statusCode === 1003) {
                setI94ClearAll(false);
                if (res.data.data.length > 0) {
                    setI94EditState('view');
                    let data = res.data.data[0]
                    if (data.documents.length === 0) {
                        data.documents = [
                            {
                                name: '',
                                id: '',
                                new_document_id: '',
                                document_url: '',
                                slug: ""
                            }
                        ]
                        setI94({ ...data })
                    }
                    else {
                        setI94(data);
                    }
                } else {
                    setI94EditState('new');
                    setI9SaveBtn(true);
                    if (inviteData != null) {
                        handleFormStates(inviteData, "i94");
                    } else {
                        if (res.data.data.length === 0) {
                            setI94({
                                ...i94
                                // id: '',
                                // valid_from: '',
                                // valid_till: '',
                                // document_number: '',
                                // country_id: '',
                                // documents: [
                                //     {
                                //         name: '',
                                //         id: '',
                                //         new_document_id: '',
                                //         document_url: '',
                                //         slug: ""
                                //     }
                                // ]
                            })
                        }
                    }
                }
                setI94Error({ document_number: '' })
            }
        })
    }

    const accordionHandler = (args) => {
        if (args == 'passport') {
            if (passport.id != "") {
                setEditState('view');
            }
            PassIndexApi(emp_id);
            setExpand(!expand);
        } else if (args == 'i94') {
            if (i94.id != "") {
                setI94EditState('view');
            }
            i94IndexApi(emp_id);
            setI94Expand(!i94expand)
        } else if (args == 'visa') {
            if (visa.id != "") {
                setVisaEditState('view');
            }
            visaIndexApi(emp_id);
            setVisaexpand(!visaexpand)
        }
    }

    const passportEdit = (e, args) => {
        if (args == 'passport') {
            setExpand(true);
            setSaveBtn(true)
            setEditState('edit');
            setClearAll(true);
            e.stopPropagation();
        } else if (args == 'I94') {
            setI94Expand(true);
            setI9SaveBtn(true)
            setI94EditState('edit');
            // setVisaClearAll(true);
            e.stopPropagation();
        } else if (args == 'visa') {
            setVisaexpand(true);
            setVisaSaveBtn(true);
            setVisaEditState('edit');

            // setVisaClearAll(true);
            setDeleteDocsEnable(true);
            e.stopPropagation();
        }
    }

    const handleClearOpen = (param) => {
        if (param == 'passport') {
            if (findKeysWithEmptyValues(passport).length > 0) {
                setClearItem(param);
                setClearAlert(true);
            } else {
                clearData(param)
            }

        } else if (param == 'I94') {
            if (findKeysWithEmptyValues(i94).length > 0) {
                setClearItem(param);
                setClearAlert(true);
            } else {
                clearData(param)
            }
        } else if (param == 'visa') {
            if (findKeysWithEmptyValues(visa).length > 0) {
                setClearItem(param);
                setClearAlert(true);
            } else {
                clearData(param)
            }
        }
    }

    const clearData = (args) => {
        if (args == 'passport') {
            setPassport({
                ...passport,
                // id: '',
                issued_country_id: '',
                valid_from: '',
                valid_till: '',
                document_number: '',
                documents: [
                    {
                        name: '',
                        id: '',
                        new_document_id: '',
                        document_url: '',
                        slug: ""
                    }
                ]
            })
            if (inviteData != null) {
                inviteData.upload_documents.passport.data = [];
                setInviteData({ ...inviteData });
            }
            setPassportClearAll(true);
            setClearAll(false);
        } else if (args == 'I94') {
            setI94({
                ...i94,
                valid_from: '',
                valid_till: '',
                document_number: '',
                country_id: '',
                expiry_type: '',
                documents: [
                    {
                        name: '',
                        id: '',
                        document_url: '',
                        new_document_id: '',
                        slug: ""
                    }
                ]
            })
            if (inviteData != null) {
                inviteData.upload_documents.i94.data = [];
                setInviteData({ ...inviteData });
            }
            setI94ClearAll(true);
        } else if (args == 'visa') {
            // setVisa({
            // ...visa,
            visa.visa_type_id = LocalStorage.getVisaID() ? parseInt(LocalStorage.getVisaID()) : '';
            // id= '';
            visa.valid_from = '';
            visa.valid_till = '';
            // document_number= '';
            visa.support_documents_deleted_ids = visa.support_documents.length > 0 ? deletIdsRe(visa.support_documents) : [];
            visa.support_documents = []
            // })
            setVisa({ ...visa });
            if (inviteData != null) {
                inviteData.upload_documents.work_authorization.data = [];
                setInviteData({ ...inviteData });
            }
            setVisaClearAll(true);
        }
        setClearItem('');
        setClearAlert(false);
    }

    const deletIdsRe = (arr) => {
        var a = [];
        for (var i in arr) {
            if (arr[i].id !== "") {
                a.push(arr[i].id);
            }
        }
        return a
    }

    const cancelPassport = () => {
        setExpand(!expand);
        if (passport.id !== '' && passport.id !== undefined && passport.id !== null) {
            setEditState('view');
            // PassIndexApi(emp_id);
        }
        // else {
        //     if (inviteData != null) {
        //         handleFormStates(inviteData, "passport");
        //     }
        //     else {
        //         setPassport({
        //             id: '',
        //             issued_country_id: '',
        //             valid_from: '',
        //             valid_till: '',
        //             document_number: '',
        //             documents: [
        //                 {
        //                     name: '',
        //                     id: '',
        //                     new_document_id: '',
        //                     document_url: '',
        //                     slug: ""
        //                 }
        //             ]
        //         })
        //     }
        // }

        setError({
            ...error,
        })
    }

    const I94Cancel = () => {
        setI94Expand(false)
        if (i94.id !== '' && i94.id !== undefined && i94.id !== null) {
            setI94EditState('view');
            // i94IndexApi(emp_id);
        }
        // else {
        //     setI94({
        //         id: '',
        //         valid_from: '',
        //         valid_till: '',
        //         document_number: '',
        //         country_id: '',
        //         documents: [
        //             {
        //                 name: '',
        //                 id: '',
        //                 new_document_id: '',
        //                 document_url: '',
        //                 slug: ""
        //             }
        //         ]
        //     })
        // }
        setI94Error({
            ...error,
        })
    }

    const cancelVisa = () => {
        setVisaexpand(false)
        if (visa.id !== '' && visa.id !== undefined && visa.id !== null) {
            setVisaEditState('view');
            // visaIndexApi(emp_id);
        }
        // else {
        //     setVisa({
        //         visa_type_id: '',
        //         id: '',
        //         valid_from: '',
        //         valid_till: '',
        //         support_documents: []
        //     })
        // }
        setVisaError({
            ...error,

        })
    }

    const skipDetails = () => {
        setValue(value + 1);
        setDocStepper(2);
    }

    const goToHome = () => {
        setSuccess(false);
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeFromPage();
        navigate('/employees');
    }

    const handleVisaDelete = (index) => {
        if (visaEditState == 'view') {
            return false;
        } else {
            if (visa.id !== "" && visa.support_documents[index].id != "") {
                visa.support_documents_deleted_ids.push(visa.support_documents[index].id);
            }
            visa.support_documents.splice(index, 1);
            setVisa({ ...visa });
        }
        if (visa.support_documents.length == 0) {
            visaError['visa_type_id'] = "";
            setVisaError({ ...visaError });
        }
    }

    const handleDeleteOpen = (index, type) => {
        if (type == "visa" && visaEditState == 'view') {
            return false;
        }
        setDeleteIndex(index);
        setDeleteItem(type);
        setDeleteOpen(true);
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    return (
        <Grid container pt={15} justifyContent='center' pb={2}>
            <Grid item container xl={5.8} lg={6.8} md={7.5}>
                <SwipeableViews index={value} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {
                            docStepper == 1 &&
                            <Grid item container lg={12}>
                                <Text largeBlack>Work Authorization</Text>
                                <Grid item lg={12} md={12} p={'30px 0px 10px 0px'}>
                                    <CustomAccordion
                                        icon={expand ? '' : passport.id !== "" ? 2 : 1}
                                        expanded={expand}
                                        handlechangeaccordion={() => accordionHandler('passport')}
                                        AccordionHeader={
                                            <Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                                                <Box>
                                                    <Text headerBlack>Passport</Text>
                                                </Box>
                                                <Box textAlign='end'>
                                                    {(expand && passport.id !== "" && editState == 'view') && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => passportEdit(e, 'passport')} />}
                                                </Box>
                                            </Box>
                                        }
                                    >
                                        {
                                            formLoader ?
                                                <Box className={classes.ViewContainer}>
                                                    <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                        <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                                        <Text mediumBlue>Please Wait </Text>
                                                    </Stack>
                                                </Box> :
                                                <Grid container spacing={2} p={'0px 10px'}>
                                                    {
                                                        passport.documents.map((item, index) => (
                                                            <Grid item lg={12} md={12} mt={2} pb={2}>
                                                                <FileInput
                                                                    name='new_document_id'
                                                                    FileName={item.name}
                                                                    handleChange={(e) => changeHandler(e, index, 'passport')}
                                                                    label={`Passport Document ${item.name ? '' : "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"} `}
                                                                    handleDelete={() => handleDeleteOpen(index, 'passport')}
                                                                    actionState={item.name ? 1 : ''}
                                                                    disabled={editState == 'view'}
                                                                    error={''}
                                                                    documentUrl={item.document_url}
                                                                    state={item}
                                                                />

                                                            </Grid>
                                                        ))
                                                    }
                                                    <Grid item lg={6} md={6}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'document_number',
                                                                value: passport.document_number,
                                                                inputProps: { minLength: 8, maxLength: 14 },
                                                                disabled: editState == 'view'
                                                            }}
                                                            disabled={editState == 'view'}
                                                            handleChange={(e, index) => changeHandler(e, index, 'passport')}
                                                            onKeyPress={onCharactersAndNumbersChange}
                                                            clientInput
                                                            labelText={`Passport Number`}
                                                            error={error.document_number && error.document_number}
                                                        />
                                                        {error.document_number && <Text red>{error.document_number ? error.document_number : ''}</Text>}
                                                    </Grid>
                                                    <Grid item lg={6} md={6}>
                                                        <Box>
                                                            <Select
                                                                name='issued_country_id'
                                                                value={passport.issued_country_id}
                                                                onChange={(e, index) => changeHandler(e, index, 'passport')}
                                                                options={countries}
                                                                disabled={editState == 'view'}
                                                                label={`Issued Country ${editState == 'view' ? '' : "(Optional)"}`}
                                                                helperText={''}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Box pt={'9px'}>
                                                            <Date
                                                                labelText={`Date Of Issue ${editState == 'view' ? '' : "(Optional)"}`}
                                                                name='valid_from'
                                                                value={passport.valid_from}
                                                                maxDate={moment().format(dateFormat())}
                                                                onChange={(value => dateChange(value, 'valid_from', 'passport'))}
                                                                disabled={editState == 'view'}
                                                                error={''}
                                                                height='56px'
                                                            />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Box pt={'9px'}>
                                                            <Date
                                                                labelText={`Date Of Expiry ${editState == 'view' ? '' : "(Optional)"}`}
                                                                name='valid_till'
                                                                minDate={moment(passport.valid_from).format(dateFormat())}
                                                                value={passport.valid_till}
                                                                disabled={editState == 'view'}
                                                                onChange={(value => dateChange(value, 'valid_till', 'passport'))}
                                                                height='56px'
                                                                error={''}
                                                            />
                                                        </Box>

                                                    </Grid>

                                                    {
                                                        (saveBtn && editState !== 'view') &&
                                                        <Grid item container m={'10px 0px 10px 0px'}>
                                                            <Grid item lg={6} md={6}>
                                                                <Button blackCancel onClick={() => { cancelPassport() }}>Cancel</Button>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} textAlign='end'>
                                                                <Button clearall sx={{ marginRight: '15px !important' }} onClick={() => handleClearOpen('passport')}>Clear all</Button>
                                                                <LoadingButton brownMnSave loading={passportLoader} onClick={handleSubmit}>Save</LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                        }
                                    </CustomAccordion>
                                </Grid>

                                <Grid item lg={12} p={'20px 0px 10px 0px'}>
                                    <CustomAccordion
                                        icon={i94expand ? '' : i94.id !== "" ? 2 : 1}
                                        expanded={i94expand}
                                        disabled={disable === 1 ? true : false}
                                        handlechangeaccordion={() => accordionHandler('i94')}
                                        AccordionHeader={
                                            <Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                                                <Box>
                                                    <Text headerBlack>I-94</Text>
                                                </Box>
                                                <Box textAlign='end'>
                                                    {(i94expand && i94.id !== "" && I94EditState == 'view') && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => passportEdit(e, 'I94')} />}
                                                </Box>
                                            </Box>
                                        }>
                                        {
                                            I94FormLoader ?
                                                <Box className={classes.ViewContainer}>
                                                    <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                        <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                                        <Text mediumBlue>Please Wait </Text>
                                                    </Stack>
                                                </Box> :
                                                <Grid container spacing={2} p={'0px 10px'}>
                                                    {
                                                        i94.documents.map((item, key) => (
                                                            <Grid item lg={12} md={12} pb={2}>
                                                                <FileInput
                                                                    name='new_document_id'
                                                                    FileName={item.name}
                                                                    viewDisplay={item.document_url}
                                                                    handleChange={(e) => changeHandler(e, key, 'i94')}
                                                                    label={`I-94 Document ${item.name ? '' : "Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"} `}
                                                                    handleDelete={() => handleDeleteOpen(key, 'i94')}
                                                                    actionState={item.name ? 1 : ''}
                                                                    disabled={I94EditState == 'view'}
                                                                    error={''}
                                                                    documentUrl={item.document_url}
                                                                    state={item}
                                                                />

                                                            </Grid>
                                                        ))
                                                    }
                                                    <Grid item lg={6} md={6}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'document_number',
                                                                value: i94.document_number,
                                                                inputProps: { maxLength: 11 },
                                                                disabled: I94EditState == 'view'
                                                            }}
                                                            disabled={I94EditState == 'view'}
                                                            handleChange={(e, index) => changeHandler(e, index, 'i94')}
                                                            clientInput
                                                            onKeyPress={onCharactersAndNumbersChange}
                                                            labelText={'I-94 Number'}
                                                            error={i94Error.document_number && i94Error.document_number}
                                                        />
                                                        {i94Error.document_number && <Text red>{i94Error.document_number ? i94Error.document_number : ''}</Text>}
                                                    </Grid>
                                                    <Grid item lg={6} md={6}>
                                                        <Box>
                                                            <Date
                                                                labelText={'Recent Date of Entry'}
                                                                name='valid_from'
                                                                value={i94.valid_from}
                                                                maxDate={moment().format(dateFormat())}
                                                                onChange={(value => dateChange(value, 'valid_from', 'i94'))}
                                                                height='56px'
                                                                disabled={I94EditState == 'view'}
                                                                error={i94Error.valid_from && i94Error.valid_from}
                                                            />
                                                            {i94Error.valid_from && <Text red>{i94Error.valid_from ? i94Error.valid_from : ''}</Text>}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={6} md={6}>
                                                        <Box>
                                                            <Select
                                                                name='expiry_type'
                                                                value={i94.expiry_type || ''}
                                                                onChange={(e, index) => changeHandler(e, index, 'i94')}
                                                                options={expiryTypeList}
                                                                disabled={I94EditState == 'view' || i94.valid_from == ''}
                                                                label={'Admit Until Date'}
                                                                helperText={''}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        i94.expiry_type == 2 &&
                                                        (<Grid item lg={6} md={6}>
                                                            <Box>
                                                                <Date
                                                                    labelText={'Admit Until Date'}
                                                                    name='valid_till'
                                                                    value={i94.valid_till}
                                                                    defaultOpen={defaultOpen}
                                                                    minDate={moment(i94.valid_from).format(dateFormat())}
                                                                    onChange={(value => dateChange(value, 'valid_till', 'i94'))}
                                                                    height='56px'
                                                                    disabled={I94EditState == 'view'}
                                                                    error={i94Error.valid_till}
                                                                />
                                                                <Text red>{i94Error.valid_till}</Text>
                                                            </Box>

                                                        </Grid>)
                                                    }

                                                    {
                                                        (i9SaveBtn && I94EditState !== 'view') &&
                                                        <Grid item container m={'10px 0px 10px 0px'}>
                                                            <Grid item lg={6} md={6}>
                                                                <Button blackCancel onClick={() => { I94Cancel() }}>Cancel</Button>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} textAlign='end'>
                                                                <Button clearall sx={{ marginRight: '15px !important' }} onClick={() => handleClearOpen('I94')}>Clear all</Button>
                                                                <LoadingButton loading={iloader} brownMnSave onClick={handleI94Submit}>Save</LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                        }
                                    </CustomAccordion>
                                </Grid>
                                <Grid item lg={12} md={12} p={'20px 0px 10px 0px'}>
                                    <CustomAccordion
                                        icon={visaexpand ? '' : visa.id !== "" ? 2 : 1}
                                        expanded={visaexpand}
                                        handlechangeaccordion={() => accordionHandler('visa')}
                                        AccordionHeader={
                                            <Box justifyContent='space-between' display='flex' flexDirection='row' width='100% !important' p={'0px 0px 0px 10px'}>
                                                <Box>
                                                    <Text headerBlack>Visa</Text>
                                                </Box>
                                                <Box textAlign='end'>
                                                    {(visaexpand && visa.id !== "" && visaEditState == 'view') && <img src={editIcon} alt='editIcon' className={classes.edit} onClick={(e) => passportEdit(e, 'visa')} />}
                                                </Box>
                                            </Box>
                                        }
                                    >
                                        {
                                            visaLoader ?
                                                <Box className={classes.ViewContainer}>
                                                    <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                        <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                                        <Text mediumBlue>Please Wait </Text>
                                                    </Stack>
                                                </Box> :
                                                <Grid container spacing={2} p={'0px 10px'}>
                                                    {/* {LocalStorage.getVisaID() && */}
                                                    <Grid item lg={6} md={6}>
                                                        <Box>
                                                            <Select
                                                                name='visa_type_id'
                                                                value={visa.visa_type_id}
                                                                onChange={(e) => { handleVisaChange(e) }}
                                                                options={visaTypes}
                                                                label={'Visa Type'}
                                                                disabled={visaEditState == 'view' || LocalStorage.getVisaID()}
                                                                helperText={visaError.visa_type_id ? <Text red>{visaError.visa_type_id ? visaError.visa_type_id : ''}</Text> : ""}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {/* } */}
                                                    <Grid item lg={4}></Grid>
                                                    {
                                                        visaEditState == 'view' ?
                                                            null :
                                                            <Grid item lg={12} md={12}>
                                                                <Box className={classes.dropzoneMainBox}>
                                                                    <Box className={classes.dropBox1}>
                                                                        <Dropzone onDrop={acceptedFiles => { visaSupportingDocsUpload(acceptedFiles) }}>
                                                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                                                <section className={visa.support_documents.length < 10 ? classes.dropZone : classes.dropZoneDisable}>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            isDragActive ?
                                                                                                <Grid container spacing={1} justifyContent='center'>
                                                                                                    <Text normal my={10} className={classes.dropzoneText}>drop field and uploaded documents similar to work authorization documents</Text>
                                                                                                </Grid>
                                                                                                :
                                                                                                <Grid container spacing={1} textAlign='center'>
                                                                                                    <Grid item lg={12} marginTop={'-8px'}>
                                                                                                        <img src={download} alt='download' />
                                                                                                    </Grid>
                                                                                                    <Grid item lg={12} textAlign='center'>
                                                                                                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                                                            <Text largeBlack>Drag & Drop Documents</Text>
                                                                                                            <Text smallGrey>or</Text>
                                                                                                            {
                                                                                                                visa.support_documents.length < 10 ?
                                                                                                                    <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text> :
                                                                                                                    <Text largeBlack sx={{ color: "#525252 !important", textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                                                                            }

                                                                                                            <Text largeBlack>To Upload</Text>
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                    <Grid item lg={12} mt={1}>
                                                                                                        <Text mediumOrange>*Add visa documents like EAD, I20's, I983, I-129, I-797, <br />GC, GC EAD, etc</Text>
                                                                                                    </Grid>
                                                                                                    <Grid item lg={12} mt={1}>
                                                                                                        <Text smallGreyFont>Document Size 25MB, Format - PDF, Docs</Text>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                        }
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                        {/* <DropZone callApi={visaSupportingDocsUpload} multiDocs={true} /> */}
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                    }
                                                    <Grid item lg={12} md={12} sx={{
                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                            marginTop: "20px"
                                                        },
                                                    }}>
                                                        {
                                                            visa.support_documents.length > 0 ?
                                                                <Box sx={{ border: visaEditState == 'view' ? "none" : "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: visaEditState == 'view' ? "#FAFAFA" : "#ffffff" }}>
                                                                    <Text smallLabel sx={{ font: "12px Quicksand !important", color: visa.support_documents.length > 0 && "#0C75EB !important" }}>Uploaded Documents</Text>
                                                                    <Grid item container lg={12} md={12} columnGap={1.5}>
                                                                        {
                                                                            visa.support_documents.map((item, index) => (
                                                                                <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                                                    <Text onClick={() => openInNewTab(item, item.document_url)} sx={{
                                                                                        cursor: "pointer", color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important`,
                                                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                                            font: "10px Quicksand !important",
                                                                                            fontWeight: `${600} !important`
                                                                                        }
                                                                                    }}>{
                                                                                            item.document_name.length > 16 ?
                                                                                                <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                                                    {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                                                </BlackToolTip>
                                                                                                :
                                                                                                <>
                                                                                                    {item.document_name}
                                                                                                </>
                                                                                        }</Text>
                                                                                    <HighlightOffIcon sx={{
                                                                                        cursor: visaEditState == 'view' ? "text" : "pointer", color: "#737373",
                                                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                                            paddingLeft: '5px',
                                                                                            height: '17px',
                                                                                            width: '17px'
                                                                                        },
                                                                                    }} onClick={() => { /*handleVisaDelete(index)*/handleDeleteOpen(index, 'visa') }} />
                                                                                </Box>
                                                                            ))
                                                                        }
                                                                    </Grid>
                                                                </Box> :
                                                                <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                                                                    <Text smallLabel sx={{ font: "14px Quicksand !important" }}>Uploaded Documents</Text>
                                                                    <Grid item container md={12} lg={12} columnGap={1.5}>
                                                                    </Grid>
                                                                </Box>
                                                        }
                                                    </Grid>

                                                    <Grid item lg={6} md={6}>
                                                        <Box pt={'9px'}>
                                                            <Date
                                                                labelText={'Date of Issue'}
                                                                name='valid_from'
                                                                value={visa.valid_from}
                                                                maxDate={moment().format(dateFormat())}
                                                                onChange={(value => dateChange(value, 'valid_from', 'visa'))}
                                                                height='56px'
                                                                disabled={visaEditState == 'view'}
                                                                error={''}
                                                            />
                                                        </Box>
                                                        {/* {visaError.valid_from && <Text red>{visaError.valid_from ? visaError.valid_from : ''}</Text>} */}
                                                    </Grid>
                                                    <Grid item lg={6} md={6}>
                                                        <Box pt={'9px'}>
                                                            <Date
                                                                labelText={'Date of Expiry'}
                                                                name='valid_till'
                                                                minDate={moment(visa.valid_from).format(dateFormat())}
                                                                value={visa.valid_till}
                                                                onChange={(value => dateChange(value, 'valid_till', 'visa'))}
                                                                height='56px'
                                                                disabled={visaEditState == 'view'}
                                                                error={''}
                                                            />
                                                        </Box>
                                                        {/* {visaError.valid_till && <Text red>{visaError.valid_till ? visaError.valid_till : ''}</Text>} */}
                                                    </Grid>
                                                    {
                                                        (visaSaveBtn && visaEditState !== 'view') &&
                                                        <Grid item container m={'10px 0px 10px 0px'}>
                                                            <Grid item lg={6} md={6}>
                                                                <Button blackCancel onClick={() => cancelVisa()}>Cancel</Button>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} textAlign='end'>
                                                                <Button clearall sx={{ marginRight: '15px !important' }} onClick={() => handleClearOpen('visa')}>Clear all</Button>
                                                                <LoadingButton loading={visaLoaderBtn} brownMnSave onClick={handleVisaSubmit}>Save</LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                        }
                                    </CustomAccordion>
                                </Grid>
                                {/* {disable === 3 && */}
                                <Grid item container m={'20px 0px 10px 0px'}>
                                    <Grid item lg={6} md={6}>
                                        <Button blackCancel onClick={workAuthBack} disable={location.state !== null && (location.state.stage === 'General Details' || location.state.stage === 'Documents') ? true : false}>Back</Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} textAlign='end'>
                                        <Button blackCancel sx={{ border: 'none !important', marginRight: '10px' }} onClick={() => { skipDetails() }} >Skip</Button>
                                        <Button saveNcontinue onClick={nextStep}>Save & Continue</Button>
                                    </Grid>
                                </Grid>
                                {/* } */}

                            </Grid>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        {
                            docStepper == 2 &&
                            <EducationalDetails setInviteData={setInviteData} inviteData={inviteData} value={value} setValue={setValue} docStepper={docStepper} setDocStepper={setDocStepper} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        {docStepper == 3 &&
                            <PersonalDocuments setInviteData={setInviteData} inviteData={inviteData} value={value} setValue={setValue} docStepper={docStepper} setDocStepper={setDocStepper} mainStep={mainStep} setMainStep={setMainStep} />
                        }
                    </TabPanel>
                </SwipeableViews>
            </Grid>
            {clearAlert && <ReusableDelete clear={true} open={clearAlert} setOpen={setClearAlert} onClick={() => { clearData(clearItem) }} />}
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(deleteIndex, deleteItem) }} />}
            {
                open &&
                <ReusablePopup openPopup={open} setOpenPopup={setOpen} crossIcon iconHide white>
                    <Box textAlign='center' p={'0px 20px'}>
                        <img src={draft} alt='draft' style={{ height: '130px', width: '150px' }} />
                        <Text veryLargeLabel>Save as Draft!</Text>
                        <Text mediumLabel sx={{ paddingTop: '20px' }}>Your progress will be saved, and you will be able to<br /> continue from the next stage when you return.</Text>
                        <Button blueButton sx={{ marginTop: '20px' }} onClick={() => navigate('/employees')}>Done</Button>
                    </Box>
                </ReusablePopup>
            }
            {
                success &&
                <ReusablePopup openPopup={success} setOpenPopup={setSuccess} white iconHide fixedWidth>
                    <Box textAlign='center' justifyContent='center' p={'20px 0px'}>
                        <img src={successImg} alt='success' style={{ height: '150px', width: '150px', marginBottom: '5px' }} />
                        <Text largeGreen>Congratulations</Text>
                        <Text mediumBlack sx={{ marginTop: '8px !important' }}>You Have Successfully Onboarded <span style={{ color: `${blue}` }}>&nbsp;{LocalStorage && LocalStorage.getFullName()}</span></Text>
                        <Button brownMnSave sx={{ margin: '20px 0px 0px 0px !important' }} onClick={goToHome}>Go To Home</Button>
                    </Box>
                </ReusablePopup>
            }
        </Grid >
    )
}

export default Documents