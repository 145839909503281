import Snackbar from '../components/snackbar/Snackbar';
import React from "react";
import ReactDOM from "react-dom";
import Axios from 'axios';
import { Dialog, linearProgressClasses, LinearProgress, Box, Grid, Typography, Button, Slide } from "@mui/material";
import LocalStorage from "./LocalStorage";
import success from '../assets/employee/check-circle.svg';
import error from '../assets/employee/alert-triangle.svg';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Text from '../components/customText/Text';
import noDataFound from '../assets/svg/noDataFound.svg';
import no_permission from '../assets/svg/no_permission.svg';
import LoaderIcon from '../assets/gif/japfuLoader.gif';
import planExpired from '../assets/svg/planExpired.svg'
import CommonApi from '../apis/CommonApi';
import { domain } from '../config/Domain';
import { useTheme } from '@mui/material';
import API_URL from '../config/development';
import { getInvalidZipcodeMessage, validate_zipcode } from '../components/Validation';
import ListLoaderIcon from '../assets/gif/japfu_loader.gif';

export function getAxios() {
  const axios = Axios;
  // const userId = LocalStorage.getUser() && LocalStorage.getUser().user_id
  // axios.defaults.headers.common['Authorization'] = `${userId}`
  return axios;
}

export function connectionFailed() {
  ReactDOM.render(
    <Dialog
      open={true}
      fullWidth={true}
      fullScreen
      PaperComponent="div"
      PaperProps={{
        style: {
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(10px)",
        },
      }}
    >
      {/* <CircularProgress color="primary" size={100} thickness={1.5} /> */}
      <img
        src={LoaderIcon}
        width={'60px'} height={'60px'}
        // style={{ borderRadius: "50%" }}
        alt="loader"
      />
      <h2>
        {window.navigator.onLine
          ? window.navigator.connection.effectiveType.endsWith("2g")
            ? "No Internet Connection, Please connect to the internet and refresh your page."
            : "Server under maintenance! Please try again after sometime"
          : "No Internet Connection, Please connect to the internet and refresh your page."}
      </h2>
    </Dialog>,
    document.getElementById("loader")
  );
}

export function addLoader(props) {
  ReactDOM.render(
    <Dialog
      open={true}
      fullWidth={true}
      fullScreen
      PaperComponent="div"
      PaperProps={{
        style: {
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Grid container>
        <Grid item container justifyContent={'center'} alignContent={'center'}>
          <img
            src={LoaderIcon}
            width={'100px'} height={'100px'}
            // style={{ borderRadius: "50%" }}
            alt="loader"
          />
        </Grid>
        {
          props === undefined &&
          <Grid container>
            <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} mt={2} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
              Mivi AI is fetching required fields from the document.
            </Grid>
            <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
              Please Wait...
            </Grid>
          </Grid>
        }

      </Grid>
      {/* <CircularProgress color="inherit" /> */}
      {/* <CircularProgress color="primary" size={100} thickness={1.5} /> */}
    </Dialog>,
    document.getElementById("loader")
  );
}

export const eventSource = LocalStorage?.getUserData()?.access_token ? new EventSource(API_URL.SSE_URL + `notificationsEvent?token=${LocalStorage.getUserData().access_token}`) : '';


export function addLoaderPlanExpired(props) {
  const RenewPlan = () => {
    // let planDetails = LocalStorage.getPlanCheckLocal()
    let url = `https://${domain}/check-billing`
    if (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) {
      window.location.replace(`http://localhost:3000/check-billing`);
    }
    else {
      window.location.replace(url)
    }
    // navigate('/billing', {
    //   state: {
    //     renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
    //       ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && planDetails.subscription_expiring) ? 'renew' :
    //         (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : '',
    //     renewal: planDetails.subscription_expiring ? true : false
    //   }
    // })
  }
  ReactDOM.render(
    <Dialog
      open={true}
      fullWidth={true}
      fullScreen
      PaperComponent="div"
      PaperProps={{
        style: {
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(0.3px)",
          backgroundColor: 'rgb(253 253 254 / 86%)'
        },
      }}
    >
      <Grid container>
        <Grid item container justifyContent={'center'} alignContent={'center'}>
          <img
            src={planExpired}
            width="150"
            height="150"
            // style={{ borderRadius: "50%" }}
            alt="expiredLoader"
          />
        </Grid>
        <Grid container>
          <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} mt={2} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Uh..Ohh!!!</Text>
          </Grid>
          <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
            {
              LocalStorage.getUserData().super_admin
                ?
                <Text offBoardBodyFont>Your Japfu subscription has expired. Renew your plan to</Text>
                :
                LocalStorage.getUserData().admin_login ?
                  <Text offBoardBodyFont>Your organization's Japfu subscription has expired. To continue,</Text>
                  :
                  <Text offBoardBodyFont>The organization's Japfu subscription has expired. Please reach out</Text>
            }
          </Grid>
          <Grid item container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
            {
              LocalStorage.getUserData().super_admin
                ?
                <Text offBoardBodyFont>continue.</Text>
                :
                LocalStorage.getUserData().admin_login ?
                  <Text offBoardBodyFont>please reach-out to your Super Admin for renewal.</Text>
                  :
                  <Text offBoardBodyFont>to your Admin for renewal.</Text>
            }
          </Grid>
          {
            LocalStorage.getUserData().super_admin
              ?
              <Grid container item justifyContent={'center'} alignContent={'center'}>
                {/*<Grid item pr={2} xs={6} pt={2} container justifyContent={'flex-end'} alignContent={'center'} color={'#fdfdfedb'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
                  <Button onClick={() => { RenewPlan() }} sx={{ background: "#fefefe00 !important", color: "#0C75EB !important", textTransform: 'capitalize !important', fontSize: '15px !important', fontFamily: 'Quicksand !important', fontWeight: '600 !important', width: '210px', height: '44px', border: '1px solid #0C75EB', borderRadius: '8px' }}>Upgrade plan</Button>
                </Grid>*/}
                <Grid item pl={2} xs={12} pt={2} container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
                  <Button onClick={() => { RenewPlan() }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontSize: '15px !important', fontFamily: 'Quicksand !important', fontWeight: '600 !important', width: '30%', height: '44px', borderRadius: '8px' }}>Renew</Button>
                </Grid>
              </Grid>
              :
              <Grid item pt={2} container justifyContent={'center'} alignContent={'center'} color={'white'} fontFamily={'Quicksand !important'} fontWeight={'700 !important'}>
                <Button onClick={() => { localStorage.clear(); window.location.reload() }} sx={{ background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', fontFamily: 'Quicksand !important', fontSize: '15px !important', fontWeight: '600 !important', width: '300px', height: '44px', borderRadius: '8px' }}>Log-Out</Button>
              </Grid>
          }

        </Grid>
      </Grid>
      {/* <CircularProgress color="inherit" /> */}
      {/* <CircularProgress color="primary" size={100} thickness={1.5} /> */}
    </Dialog>,
    document.getElementById("expiredLoader")
  );
}


export function removeLoader() {
  ReactDOM.unmountComponentAtNode(document.getElementById("loader"));
}
export function removeExpiredLoader() {
  ReactDOM.unmountComponentAtNode(document.getElementById("expiredLoader"));
}

export function addSuccessMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="success"
      place="bc"
      icon={<img src={success} alt='success' />}
      renderElement={document.getElementById("error")}
      sx={{ zIndex: '1' }}
    />,
    document.getElementById("error")
  );
}

export function addWarningMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="warning"
      place="bc"
      icon={<img src={error} alt='warning' />}
      renderElement={document.getElementById("error")}
      sx={{ zIndex: '1' }}
    />,
    document.getElementById("error")
  );
}

// export function addWarningMsgs(messages) {
//   const concatenatedMessages = messages.join('<br/>'); // Use <br/> for line breaks
//   const formattedMessage = <span dangerouslySetInnerHTML={{ __html: concatenatedMessages }} />;

//   ReactDOM.render(
//     <Snackbar
//       message={formattedMessage}
//       color="warning"
//       place="bc"
//       icon={<img src={warning} alt='warning' />}
//       renderElement={document.getElementById("error")}
//     />,
//     document.getElementById("error")
//   );  
// }

export function addErrorMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="danger"
      place="bc"
      icon={<img src={error} alt='error' />}
      renderElement={document.getElementById("error")}
      sx={{ zIndex: '1' }}
    />,
    document.getElementById("error")
  );
}


export function dateFormat() {
  const dateFormat = LocalStorage.getDateFormat() ? LocalStorage.getDateFormat() : "MM/DD/YYYY";
  return dateFormat
}

export function formEdit() {
  const dateFormat = LocalStorage.getEditForm() == 'edit' ? true : false
  return dateFormat
}

export function getCountryDetails() {
  var localData = []
  localData = LocalStorage.getCountryData()
  if (localData != null) {
    return localData
  }
  else {
    CommonApi.getCountryList('').then((result) => {
      if (result.data.statusCode === 1003) {
        LocalStorage.setCountryData(result.data.data);
        return result.data.data
      }
    })
  }
}

export function getCurrencySymbol() {
  return LocalStorage.getCurrencySymbol();
}

export const BlackToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "5px",
    minWidth: 80,
    border: "1px solid #404040",
    borderRadius: "7px",
    // font:"10px !important"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));

export const WhiteToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: 'black',
    padding: "5px 0px 5px 10px",
    minWidth: 20,
    border: "1px solid #393939",
    borderRadius: "12px",
    // font:"10px !important"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#393939",
    "&::before": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #393939"
    }
  },
}));

export const greyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: 'black',
    padding: "5px 0px 5px 10px",
    minWidth: 20,
    border: "1px solid #393939",
    borderRadius: "12px",
    // font:"10px !important"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#393939",
    "&::before": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #393939"
    }
  },
}));

// export const socket = io(`${API_URL.Socket_URL}`, { transports: ["websocket"], auth: { token: LocalStorage.getAccessToken() }, });

export const BorderLinearProgress = styled(LinearProgress)(({ theme, bgColor, barColor }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: bgColor,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
}));

export var rolePermission = LocalStorage.getRolesData()
  ? LocalStorage.getRolesData().role_permissions.permissions
  : "";

export function filterRole(args) {
  if (LocalStorage.getUserData().super_admin) {
    return true;
  } else if (rolePermission != "") {
    var x = rolePermission.find(
      (i) => i.slug == args && i.is_allowed == true
    );
    if (x) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function capitalizeAndAddSpace(str) {
  if (str !== "" && str !== undefined && str !== null) {
    // Split the string into an array of words
    let words = str.split(' ');
    // Capitalize the first letter of each word
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with a space between them
    let result = words.join(' ');
    return result;
  } else {
    return false;
  }

}

export function capitalize(str) {
  if (str == '' || str == null || str == undefined) {
    return ''
  }
  else {
    let words = str.split(' ');
    // Capitalize the first letter of each word
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    // Join the words with a space between them
    let result = words.join(' ');
    return result;
  }
}

export function formatString(str) {
  // Split the string into words
  var words = str.split('_');

  // Capitalize the first letter of each word
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together with spaces
  return words.join(' ');
}


export function NoDataFound(param, param1, param2, small) {
  // param for extra content placed in the below of no data found
  return (
    <Box sx={{ height: small ? `${small}vh` : "60vh", width: "100%", textAlign: "center" }}>
      <img src={noDataFound} alt="noDataFound" style={{ paddingTop: "15px" }} height={param2 ? param2 : ""} />
      <Text sx={{ font: "18px Quicksand !important", fontWeight: `${700} !important`, color: "#262626 !important", marginTop: "10px !important" }}>{param1}</Text>
      <Text sx={{ font: "14px Quicksand !important", fontWeight: `${400} !important`, color: "#525252 !important", marginTop: "10px !important" }}>{param}</Text>
    </Box>
  );
}

export function NoPermission() {
  // param for extra content placed in the below of no data found
  return (
    <Box sx={{ height: "60vh", width: "100%", textAlign: "center" }}>
      <img src={no_permission} alt="no_permission" style={{ paddingTop: "15px" }} />
      <Text sx={{ font: "18px Quicksand !important", fontWeight: `${700} !important`, color: "#54595E !important", marginTop: "10px !important" }}>Permission Not Given!</Text>
      <Text sx={{ font: "14px Quicksand !important", fontWeight: `${400} !important`, color: " #54595E99 !important", marginTop: "10px !important" }}>You have no access for this module.</Text>
    </Box>
  );
}
export function NoPermissionSmall() {
  // param for extra content placed in the below of no data found
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <img src={no_permission} alt="no_permission" style={{ paddingTop: "15px" }} />
      <Text sx={{ font: "18px Quicksand !important", fontWeight: `${700} !important`, color: "#54595E !important", marginTop: "10px !important" }}>Permission Not Given!</Text>
      <Text sx={{ font: "14px Quicksand !important", fontWeight: `${400} !important`, color: " #54595E99 !important", marginTop: "10px !important" }}>You have no access for this module.</Text>
    </Box>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));

export function NotApplicable() {
  // param for extra content placed in the below of no data found
  return (
    <HtmlTooltip
      placement="bottom"
      arrow
      title={
        <React.Fragment>
          <Box>
            <Typography sx={{
              fontSize: "11px !important",
              fontFamily: "Quicksand !important",
              fontWeight: "500 !important",
              color: "#FFFFFF !important",
              [useTheme().breakpoints.down('xl')]: {
                font: '10px Quicksand !important',
                fontWeight: `${500} !important`,
              }
            }}>
              not applicable
            </Typography>
          </Box>
        </React.Fragment>
      }
    >
      N/A
    </HtmlTooltip>
  );
}

// Function to find keys with empty values in an object
export function findKeysWithEmptyValues(obj) {
  // Array to store keys with empty values
  const keysWithEmptyValues = [];

  // Function to check if a value is empty
  const checkEmpty = (value) => {
    return (
      // Check if value is empty string, null, or undefined
      value === '' ||
      value === null ||
      value === undefined ||
      // Check if value is an empty array
      (Array.isArray(value) && value.length === 0) ||
      // Check if value is an empty object
      (typeof value === 'object' && Object.keys(value).length === 0)
    );
  };

  // Recursive function to traverse the object and find keys with empty values
  const traverse = (obj) => {
    for (const key in obj) {
      // Check if the property is directly on the object, not inherited
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];

        // If the value is empty, add the key to keysWithEmptyValues
        if (checkEmpty(value)) {
          keysWithEmptyValues.push(key);
        }
        // If the value is an object, recursively call traverse function
        else if (typeof value === 'object') {
          traverse(value);
        }
      }
    }
  };

  // Start traversing the object
  traverse(obj);

  // Return the array containing keys with empty values
  return keysWithEmptyValues;
};

export function openDocumentInNewtab(key, documentUrl) {
  let data = {
    request_id: LocalStorage.uid(),
    document_key: documentUrl
  }
  addLoader(true);
  CommonApi.documentViewS3(key, data).then((res) => {
    removeLoader();
    if (res.data.statusCode == 1003) {
      const newWindow = window.open(res.data.data.document_url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  })
}

export function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

export const LinearProgressZoho = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export const LinearProgressBundle = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#007050' : '#919EAB',
  },
}));

export const checkZipcode = async (data) => {
  let val = '';

  if (validate_zipcode(data) === '') {
    try {
      const res = await CommonApi.getZipcodeData(data);
      if (res.data.statusCode === 1003) {
        if (res.data.data.length === 0) {
          val = getInvalidZipcodeMessage(data);
        }
      } else {
        val = getInvalidZipcodeMessage(data);
      }
    } catch (error) {
      val = getInvalidZipcodeMessage(data); // Handle API errors as invalid
    }
  } else {
    val = getInvalidZipcodeMessage(data);
  }

  return val;
};

export const checkZipcodewithoutToken = async (data) => {
  let val = '';

  if (validate_zipcode(data) === '') {
    try {
      const res = await CommonApi.getZipcodeDataWithoutToken(data);
      if (res.data.statusCode === 1003) {
        if (res.data.data.length === 0) {
          val = getInvalidZipcodeMessage(data);
        }
      } else {
        val = getInvalidZipcodeMessage(data);
      }
    } catch (error) {
      val = getInvalidZipcodeMessage(data); // Handle API errors as invalid
    }
  } else {
    val = getInvalidZipcodeMessage(data);
  }

  return val;
};

export const handleW4Open = () => {
  window.open(`${process.env.REACT_APP_API_URL}documents/Default/w4.jpg`, '_blank', 'noopener,noreferrer')
}

export const handleI9Open = () => {
  window.open(`${process.env.REACT_APP_API_URL}documents/Default/i-9.png`, '_blank', 'noopener,noreferrer')
}

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

export const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scroll effect
  });
};

export const ListLoadingIcon = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

    }}>
      <img
        src={ListLoaderIcon}
        width="60px"
        height="60px"
        alt="loader"
      />
    </Box>
  )
}