import BaseApi from './BaseApi';
import APIURL from '../config/development';
import LocalStorage from "../utils/LocalStorage";
import { domain } from '../config/Domain';

class CommonApi {
    /* For main login API */
    Login(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'login', data)
    }
    Roles(id, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `role/permissions?request_id=${LocalStorage.uid()}&id=${id}`, token)
    }
    ForgotPassword(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'forgot-password', data)
    }
    subDomainCheck(data) {
        return BaseApi.postWithData(APIURL.API_URL + 'subdomain-check', data)
    }
    signupOtpCheck(data) {
        return BaseApi.postWithData(APIURL.API_URL + 'verifyOTP', data)
    }
    OtpVerification(data) {
        return BaseApi.postWithData(APIURL.API_URL + 'verify-otp', data)
    }
    resendSignupOtp(data) {
        return BaseApi.postWithData(APIURL.API_URL + 'resendOTP', data)
    }
    Logout(data, token) {
        return BaseApi.postWithToken(APIURL.AdminURL + 'logout', data, token)
    }
    storeSignUp(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'tenant/create', data)
    }

    resendEmail(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'tenant/send-link', data)
    }

    verifyAccount(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'tenant/verify-link', data)
    }

    // Upload Document
    documentUpload(slug, formdata) {
        return BaseApi.postFormData(APIURL.org_URL + 'upload/' + slug, formdata, LocalStorage.getAccessToken())
    }
    ocrDocumentUpload(slug, formdata) {
        return BaseApi.ocrPost(APIURL.org_URL + 'ocr/' + slug + '/', formdata, LocalStorage.getAccessToken())
    }
    getCountryList(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `country/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken())
    }
    getCountryListInitial(token) {
        return BaseApi.getWithParams(APIURL.org_URL + `country/dropdown?request_id=${LocalStorage.uid()}`, token)
    }
    getStatesList(country, country_code) {
        return BaseApi.getWithParams(APIURL.org_URL + `state/dropdown?request_id=${LocalStorage.uid()}&country_id=${country}&country_code=${country_code ? country_code : ''}`, LocalStorage.getAccessToken())
    }
    getNetPayTermsList(params, search, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `net-pay-terms/dropdown?request_id=${params}&search=${search}`, token)
    }

    // Add Net PayTerms
    createPaytems(data, token) {
        return BaseApi.postWithToken(APIURL.API_URL + `net-pay-terms/store`, data, token)
    }

    getCycleDropdown(params, token) {
        return BaseApi.getWithParams(APIURL.API_URL + 'cycles/dropdown?request_id=' + params, token)
    }

    netPayTermsDropDownList(params, token) {
        return BaseApi.getWithParams(APIURL.API_URL + `net-pay-terms/dropdown?request_id=${LocalStorage.uid()}`, token)
    }
    departmentList(params, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `departments/dropdown?request_id=` + params, token)
    }
    employmentTypesList(params, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `employment-types/dropdown?request_id=` + params, token)
    }
    CategoryList(params, id, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `categories/dropdown?request_id=${params}&emp_type_id=${id}`, token)
    }
    employeeTeam(params, department_id, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `employee-team/dropdown?request_id=${params}&department_id=${department_id ? department_id : ''}`, token)
    }
    EmployeeDetailsdropdown(params, id, token) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${params}&emp_type_id=${id}`, token);
    }
    EmployeeDetailsdropdownTransfer(params, id, token) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${params}&emp_type_id=${id}&ignore_login_user=1`, token);
    }
    visaTypes(params) {
        return BaseApi.getWithParams(APIURL.org_URL + `visa-types/dropdown?request_id=${params}`, LocalStorage.getAccessToken())
    }
    visaDocumentTypeList(params, id, token) {
        return BaseApi.getWithParams(APIURL.API_URL + `visa-document-types/dropdown?request_id=${params}&visa_type_id=${id}`, token)
    }
    educationLevel(params, token) {
        return BaseApi.getWithParams(APIURL.API_URL + `education-levels/dropdown?request_id=${params}`, token)
    }
    personalDocsList(params, token) {
        return BaseApi.getWithParams(APIURL.API_URL + `document-types/employee-personal-documents/dropdown?request_id=${params}`, token)
    }
    relation(params, token) {
        return BaseApi.getWithParams(APIURL.org_URL + `relationship-type/dropdown?request_id=${params}`, token)
    }
    employees(params, token, id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${params}&emp_type_id=${id ? id : ''}`, token)
    }
    employeesConsultant(params, token, id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `consultant/employee/dropdown?request_id=${params}&emp_type_id=${id ? id : ''}`, token)
    }
    activeEmployees(params, token, id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${params}&emp_type_id=${id ? id : ''}&status="Active"`, token)
    }

    employeesWithoutType(params, token) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${params}`, token)
    }

    //  employees Dropdown Api
    employeesDropdown(params) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${LocalStorage.uid()}&emp_type_id=${params.emp_type_id}&timesheet_cycle_id=${params.timesheet_cycle_id}`, LocalStorage.getAccessToken());
    }

    //  Clients End-clients Dropdown Api
    clientsEndClientsDropdown(path, search) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/${path}/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }

    // Relationship Dropdown API
    getRelationshipDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `relationship-type/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }

    // Employment Categorey Dropdown API
    getEmploymentCategoreyDropdown(search, emp_type_id) {
        return BaseApi.getWithParams(APIURL.API_URL + `categories/dropdown?request_id=${LocalStorage.uid()}&search=${search}&emp_type_id=${emp_type_id}`, LocalStorage.getAccessToken(),);
    }

    // Employment Type Dropdown API
    getEmploymentTypeDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `employment-types/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }

    // Employee Type Dropdown API
    getEmployeeDetailsdropdown(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${LocalStorage.uid()}&emp_type_id=${id}`, LocalStorage.getAccessToken());
    }

    // Departments Dropdown API
    departmentsDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `departments/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }

    // Team Dropdown API
    teamDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `employee-team/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }

    // Visa Type Dropdown API
    VisaTypeDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `visa-types/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }

    // visa-document-types  Dropdown API
    visaDocumentTypeDropdown(visa_type_id, search) {
        return BaseApi.getWithParams(APIURL.API_URL + `visa-document-types/dropdown?request_id=${LocalStorage.uid()}&visa_type_id=${visa_type_id}&search=${search}`, LocalStorage.getAccessToken());
    }

    // skills Dropdown API
    skillsDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `skills/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }


    // Education Levels Dropdown API
    educationLevelsDropdown(search) {
        return BaseApi.getWithParams(APIURL.API_URL + `education-levels/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }
    // Document Types Dropdown API
    documentTypesDropdown(search, slug) {
        return BaseApi.getWithParams(APIURL.API_URL + `document-types/${slug}/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken(),);
    }
    // payroll-config-settings Dropdown API
    payrollConfigSettingsDropdown() {
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config-settings/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken(),);
    }

    //job-titles dropdown API
    // getJobTitlesDropdownList(search) {
    //     return BaseApi.getWithParams(APIURL.API_URL + `job-title/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken())
    // }


    /*************   Prefix Api    **************/
    prefix(slug) {
        return BaseApi.getWithParams(APIURL.org_URL + `prefixes/getPrefix?request_id=${LocalStorage.uid()}&slug=${slug}`, LocalStorage.getAccessToken())
    }

    daysDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `days/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }

    placementsDropdown(id, args) {
        return BaseApi.getWithParams(APIURL.placement_URL + `placement/client/dropdown?request_id=${LocalStorage.uid()}&employee_id=${id}&calendar_view=${args}`, LocalStorage.getAccessToken());
    }

    documentsTypeDropdown(slug) {
        return BaseApi.getWithParams(APIURL.API_URL + `document-types/${slug}/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    //job-titles dropdown API
    getJobTitlesDropdownList(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `job-title/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken())
    }

    // Self Service Types Drop Down Api
    selfServiceTypesDropdown(slug, search) {
        return BaseApi.getWithParams(APIURL.API_URL + `config/${slug}/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }

    payrollConfigDropdown() {
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config-settings/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    //  Client Employees  Dropdown Api
    clientEmployeesDropdown(path, client_id) {
        return BaseApi.getWithParams(APIURL.API_URL + `ledgers/${path}/employee-details/${client_id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    //  placement Timesheet Api
    placementTimesheet(data) {
        return BaseApi.getWithParams(APIURL.API_URL + `ledgers/uninvoiced-timesheets?request_id=${LocalStorage.uid()}&placement_id=${data.placement_id}&start_date=${data.start_date}&end_date=${data.end_date}`, LocalStorage.getAccessToken());
    }

    //  companies Address list Api
    companiesAddressList(slug, company_id) {
        return BaseApi.getWithParams(APIURL.API_URL + `companies/${slug}/get-company-address?request_id=${LocalStorage.uid()}&company_id=${company_id}`, LocalStorage.getAccessToken());
    }

    paymentDropdown() {
        return BaseApi.getWithParams(APIURL.API_URL + `payment-mode/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }


    // Self Service Types Drop Down Api
    rolesDropdown(search) {
        return BaseApi.getWithParams(APIURL.org_URL + `role/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken());
    }

    getAllNotifications(data) {
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.API_URL + `/notification/listing`, data, LocalStorage.getAccessToken())
    }

    getInternalEmployees() {
        return BaseApi.getWithParams(APIURL.org_URL + `employee/internal/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    getTimesheetApprovers() {
        return BaseApi.getWithParams(APIURL.org_URL + `employee/internal/dropdown?request_id=${LocalStorage.uid()}&is_timesheet_edit_access=true`, LocalStorage.getAccessToken());
    }

    getRecruiterDropdownList() {
        return BaseApi.getWithParams(APIURL.org_URL + `recruiter/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getAllRecruiters() {
        // return BaseApi.getWithParams(APIURL.org_URL + `employee/internal/dropdown`, LocalStorage.getAccessToken());
        return BaseApi.getWithParams(APIURL.placement_URL + `recruiters?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    // getRecruiterDropdownList(search) {
    //     return BaseApi.getWithParams(APIURL.org_URL + `recruiter/dropdown?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken())
    // }

    domainConfig(data) {
        return BaseApi.getWithParams(APIURL.AdminURL + `get-domain?request_id=${LocalStorage.uid()}&email_id=${data}`, LocalStorage.getAccessToken());
    }

    verifyOtp(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'send-otp', data)
    }

    changePassword(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'change-password', data)
    }

    profileChangePassword(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + 'my-profile/change-password', data, LocalStorage.getAccessToken())
    }

    paymentInitiation(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + 'payment-initiated', data, LocalStorage.getAccessToken())
    }

    getLoginDetails(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'get-login', data)
    }

    renewInitiation(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'manage-subscription', data)
    }

    upgradeInitiation(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'upgrade-plan', data)
    }

    cancelSubscription(data) {
        return BaseApi.putWithToken(APIURL.AdminURL + 'cancel-subscription', data, LocalStorage.getAccessToken())
    }

    InternalEmployeeDetailsdropdown(id) {
        return BaseApi.getWithParams(APIURL.org_URL + `employee/role-wise-internal/dropdown/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    emailDomainCheck(data) {
        return BaseApi.postWithData(APIURL.AdminURL + 'signup-domain-check', data)
    }

    documentViewS3(path, data) {
        return BaseApi.postWithToken(APIURL.org_URL + `fetch-document/${path}`, data, LocalStorage.getAccessToken())
    }

    documentViewS3WithoutToken(path, data) {
        return BaseApi.postWithData(APIURL.org_URL + `fetch-document-without-token/${path}`, data)
    }

    defaultYearsList() {
        return BaseApi.getWithParams(APIURL.org_URL + `organization-starting-year?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    checkTokenAlready(token) {
        return BaseApi.get(APIURL.AdminURL + `check-tempaccesstoken?request_id=${LocalStorage.uid()}&temp_access_token=${token}&subdomain_name=${domain}`)
    }

    getVendorList(status) {
        return BaseApi.getWithParams(APIURL.client_URL + `company/vendor/dropdown?request_id=${LocalStorage.uid()}&status=${status}`, LocalStorage.getAccessToken())
    }

    autoPlanRenewalMain(data) {
        return BaseApi.putWithToken(APIURL.AdminURL + "main-plan-auto-renewal", data, LocalStorage.getAccessToken());
    }

    autoPlanRenewalMainAI(data) {
        return BaseApi.putWithToken(APIURL.AdminURL + "ai-timesheet-plan-auto-renewal", data, LocalStorage.getAccessToken());
    }

    cancelSubscriptionAI(data) {
        return BaseApi.putWithToken(APIURL.AdminURL + 'ai-timesheet-cancel-subscription', data, LocalStorage.getAccessToken())
    }

    calculateProrate(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + `calculate-prorate`, data, LocalStorage.getAccessToken())
    }

    getAllMasterData() {
        return BaseApi.getWithParams(APIURL.org_URL + `master-data?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getZipcodeData(zipcode) {
        return BaseApi.getWithParams(APIURL.org_URL + `getStateCityByZipcode?request_id=${LocalStorage.uid()}&zipcode=${zipcode}`, LocalStorage.getAccessToken())
    }

    getZipcodeDataWithoutToken(zipcode) {
        return BaseApi.getWithTenantParams(APIURL.org_URL + `getStateCityByZipcodeWithoutToken?request_id=${LocalStorage.uid()}&zipcode=${zipcode}`, LocalStorage.getPathId())
    }

    maritalStatusList() {
        return BaseApi.getWithParams(APIURL.org_URL + `marital-status/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getPaymentTermsDropdown() {
        return BaseApi.getWithParams(APIURL.org_URL + `net-pay-terms/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    postPaymentTermsDays(data) {
        return BaseApi.postWithToken(APIURL.org_URL + `net-pay-terms/store`, data, LocalStorage.getAccessToken())
    }

}
// eslint-disable-next-line
export default new CommonApi()