import React, { useState, useEffect } from 'react';
import { Box, Dialog, Grid, Stack } from '@mui/material';
// import Browse from '../../../../../assets/svg/Browse.svg';
import Input from '../../../../../components/input/Input';
import Text from '../../../../../components/customText/Text';
import { validate_emptyField, validates_Integer, validate_routingNumber, validate_accountNumber, validate_bank_name, validate_deposit_value } from "../../../../../components/Validation";
import Button from '../../../../../components/customButton/Button';
import EmployeeAPI from '../../../../../apis/admin/employees/EmployeesApi';
import CommonApi from '../../../../../apis/CommonApi';
import { BlackToolTip, Transition, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalizeAndAddSpace, handleI9Open, handleW4Open, openDocumentInNewtab, removeLoader } from '../../../../../utils/utils';
import LocalStorage from "../../../../../utils/LocalStorage";
import FileSvg from "../../../../../assets/svg/File.svg";
import FileSaver from 'file-saver';
import FileInput from '../../../../../components/muiFileInput/FileInput';
import NoDataImg from "../../../../../assets/images/no-data.png";
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import CustomButton from '../../../../../components/customButton/Button';
import Select from '../../../../../components/select/Select';
import EditIcon from '../../../../../assets/svg/newEdit.svg'
import offBoardSure from '../../../../../assets/svg/offBoardSure.svg';
import LoadingScreen from '../document/LoadingScreen'
import Userplus from '../../../../../assets/svg/plus.svg';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../../assets/svg/ExpenseDeleteIcon.svg';
import minus from '../../../../../assets/client/minus-circle.svg';
import sampleBankVoidCheque from '../../../../../assets/svg/sampleBankVoidCheque.png';

const AccountTypeOptions = [
    {
        id: 1,
        value: "Savings"
    },
    {
        id: 2,
        value: "Checking"
    }
];

export default function BankDetails(props) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [voidOpen, setVoidOpen] = useState(false);
    const [form, setForm] = useState(false);
    const [deleteId, setdeleteId] = useState([]);
    const [editdeleteId, setEditdeleteId] = useState(null)// eslint-disable-next-line
    const [clearPopup2, setclearPopup2] = useState(false);// eslint-disable-next-line
    const [clearPopup3, setclearPopup3] = useState(false);
    const [fullNetPopup, setFullNetPopup] = useState(false)// eslint-disable-next-line
    const [fullNetIndex, setFullNetIndex] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)

    const [depositTypeOptions, setDepositTypeOptions] = useState([{
        id: 1,
        value: 'Full Net',
        disabled: false
    },
    {
        id: 2,
        value: 'Partial $',
        disabled: false
    },
    {
        id: 3,
        value: 'Partial %',
        disabled: false
    },
    {
        id: 4,
        value: 'Remainder',
        disabled: false
    }]);
    const [state, setState] = useState({
        deposit_form_documents: [
            {
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: ''
            }
        ],
        i9_form_documents: [
            {
                id: "",
                new_document_id: "",
                document_url: "",
                document_name: "",

            }
        ],
        bank_information: [
            {
                bank_name: "",
                account_type: "",
                account_number: "",
                confirm_account_number: "",
                routing_number: "",
                confirm_routing_number: "",
                deposit_type: '',
                deposit_value: "",
                void_cheque_documents: [
                    {
                        id: "",
                        new_document_id: "",
                        document_url: "",
                        document_name: "",
                    }
                ],
            }]
    }
    );
    const [error, setError] = useState([]);
    const [w4error, setW4error] = useState({ deposit_form_documents: '' })
    const [i9error, setI9error] = useState({ deposit_form_documents: '' })
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [formLoader, setFormLoader] = useState(false);
    const [employee, setEmployee] = useState(props.employee)
    useEffect(() => {
        setEmployee(props.employee)
    }, [props])
    useEffect(() => {
        getBankDetails();
        // eslint-disable-next-line
    }, []);

    const getBankDetails = () => {
        setLoading(true)
        EmployeeAPI.getBankDetails(props.employee.id).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length == 0) {
                        setState({
                            bank_information: [],
                            deposit_form_documents: [],
                            i9_form_documents: []
                        })
                    }
                    if (response.data.data.bank_information?.length > 0) {
                        setState({ ...response.data.data });
                        if (response.data.data[0]?.deposit_type == 2) {
                            depositTypeOptions[2].disabled = true
                            setDepositTypeOptions([...depositTypeOptions])
                        } else if (response.data.data[0]?.deposit_type == 3) {
                            depositTypeOptions[1].disabled = true
                            setDepositTypeOptions([...depositTypeOptions])
                        }
                    }
                    else {
                        setState({
                            bank_information: [],
                            deposit_form_documents: [],
                            i9_form_documents: []
                        })
                    }
                } else {
                    setLoading(false)
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const addNewBank = () => {
        let errors = formValidations();
        if (state.bank_information[0].deposit_type == 1) {
            addWarningMsg('Please change the deposit configuration from full net to others.')
        }
        else {
            if (errors.length == 0 && w4error.deposit_form_documents == '' && i9error.deposit_form_documents == '') {
                state.bank_information.push({
                    bank_name: "",
                    account_type: "",
                    account_number: "",
                    confirm_account_number: "",
                    routing_number: "",
                    confirm_routing_number: "",
                    deposit_type: "",
                    deposit_value: "",
                    void_cheque_documents: [
                        {
                            id: "",
                            new_document_id: "",
                            document_url: "",
                            document_name: "",
                        }
                    ]
                })
                setState({ ...state });
            } else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        }
    }

    const removeBank = (index) => {
        deleteId.push(state.bank_information[index].id)
        setdeleteId([...deleteId]);
        state.bank_information.splice(index, 1);
        setState({ ...state });
    }

    /* fun is for Uploading Documents */
    const uploadDocs = (value, index, fieldName) => {
        addLoader(true)
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        let size = ((value.target.files[0].size / (1024 * 1024)).toFixed(2))
        if (Number(size) <= 25) {
            CommonApi
                .documentUpload("bank-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader();
                        let docInfo = response.data.data;
                        let newStateArr = { ...state };
                        if (fieldName === "void_cheque_documents") {
                            state.bank_information[index].void_cheque_documents[0].new_document_id = docInfo.id;
                            state.bank_information[index].void_cheque_documents[0].document_url = docInfo.document_url;
                            state.bank_information[index].void_cheque_documents[0].document_name = value.target.files[0].name;
                            setState({ ...state });
                        }
                        else if (fieldName === "i9_form_documents") {
                            removeLoader()
                            if (newStateArr.i9_form_documents.length == 0) {
                                newStateArr.i9_form_documents.push({
                                    new_document_id: docInfo.id,
                                    document_name: value.target.files[0].name,
                                    document_url: docInfo.document_url
                                })
                            } else {
                                newStateArr.i9_form_documents[0].new_document_id = docInfo.id;
                                newStateArr.i9_form_documents[0].document_name = value.target.files[0].name;
                                newStateArr.i9_form_documents[0].document_url = docInfo.document_url;
                            }
                            setState({ ...newStateArr });
                        }
                        else {
                            removeLoader()
                            newStateArr.deposit_form_documents[0].new_document_id = docInfo.id;
                            if (value.target.name == 'deposit_form_documents') {
                                newStateArr[value.target.name][0].document_url = docInfo.document_url;
                            }
                            else {
                                newStateArr[index][value.target.name][0].document_url = docInfo.document_url;
                            }
                            newStateArr.deposit_form_documents[0].document_name = value.target.files[0].name;
                        }
                        setState({ ...newStateArr });
                        removeLoader();
                        handleValidate(fieldName, value.target.value, index); // Pass the field name directly
                    }
                    else {
                        addErrorMsg(response.data.message);
                        removeLoader();
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }

    };

    const deleteDoc = (index, args) => {
        if (args == 'void_cheque_documents') {
            state.bank_information[index].void_cheque_documents[0].new_document_id = ''
            state.bank_information[index].void_cheque_documents[0].document_name = ''
            state.bank_information[index].void_cheque_documents[0].document_url = ''
            state.bank_information[index].void_cheque_documents[0].void_cheque_delete = true
            state.bank_information[index].void_cheque_documents[0].aws_s3_status = false
            setState({ ...state })
        } else if (args == 'deposit_form_documents') {
            state.deposit_form_documents[0].new_document_id = ''
            state.deposit_form_documents[0].document_name = ''
            state.deposit_form_documents[0].document_url = ''
            state.deposit_form_documents[0].aws_s3_status = false
            setState({ ...state })
        }
        else if (args == 'i9_form_documents') {
            state.i9_form_documents[0].new_document_id = ''
            state.i9_form_documents[0].document_name = ''
            state.i9_form_documents[0].document_url = ''
            state.i9_form_documents[0].aws_s3_status = false
            setState({ ...state })
        }
    }

    // const handleChange = (e, index) => {
    //     setCurrentIndex(index)
    //     let { name, value } = e.target;
    //     let bankDetailsArr = state.bank_information;
    //     if (name == "deposit_type") {
    //         if (index == 0) {
    //             if (value == 2 || value == 3) {
    //                 let deleteAll = deleteId;
    //                 let disable = value == 2 ? 2 : 1;
    //                 let enable = value == 2 ? 1 : 2;
    //                 const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                 deleteAll = deletedArray.map(obj => obj.id);
    //                 setdeleteId([...deleteAll]);
    //                 bankDetailsArr.splice(index + 1);
    //                 bankDetailsArr[index][name] = value
    //                 bankDetailsArr[index].deposit_value = ''

    //                 let errorBank = error[index] || {}
    //                 errorBank.deposit_value = ''
    //                 error.length = 0
    //                 error.push(errorBank)
    //                 setError([...error])
    //                 if (value == 2 && state.bank_information.length !== 5) {
    //                     addNewAccount(index, e.target.value);
    //                     // bankDetailsArr[index][name] = value
    //                 }
    //                 // else if (value == 3 && state.bank_information[index].deposit_value > 100) {
    //                 //     bankDetailsArr[index][name] = value
    //                 //     bankDetailsArr[index].deposit_value = ''
    //                 //     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value, deposit_value: '' };
    //                 // } else if (value == 3 && state.bank_information[index].deposit_value < 100 && state.bank_information[index].deposit_value !== '') {
    //                 //     addNewAccount(index, e.target.value)
    //                 //     bankDetailsArr[index][name] = value
    //                 // } else {
    //                 //     bankDetailsArr[index][name] = value
    //                 // }
    //                 depositTypeOptions[disable].disabled = true
    //                 depositTypeOptions[enable].disabled = false
    //                 setDepositTypeOptions([...depositTypeOptions]);
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //             else if (bankDetailsArr.length > 1 && value == 1) {
    //                 setFullNetIndex(index)
    //                 setFullNetPopup(true)
    //             }
    //             else {
    //                 let deleteAll = deleteId;
    //                 if (bankDetailsArr[index].id) {
    //                     if (index !== 0) {
    //                         deleteAll.push(bankDetailsArr[index].id)
    //                     }
    //                 }
    //                 setdeleteId([...deleteAll])
    //                 if (error.length > 0) {
    //                     error[index].deposit_value = ''
    //                 }
    //                 bankDetailsArr.splice(1);
    //                 bankDetailsArr[0][name] = value
    //                 bankDetailsArr[0].deposit_value = ''
    //                 // bankDetailsArr[0] = { ...bankDetailsArr[0], [name]: value, deposit_value: '' };
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //         } else {
    //             if (value == 2) {
    //                 if (state.bank_information.length !== 5 && (value !== state.bank_information[index].deposit_type)) {
    //                     if (state.bank_information.length > 1) {
    //                         if (state.bank_information[index - 1].deposit_type == e.target.value) {
    //                             addNewAccount(index, e.target.value)
    //                             bankDetailsArr[index][name] = value
    //                             depositTypeOptions[2].disabled = true
    //                             setDepositTypeOptions([...depositTypeOptions])
    //                             setState({
    //                                 ...state,
    //                                 bank_information: [...bankDetailsArr]
    //                             });
    //                         }
    //                         else {
    //                             addErrorMsg('You cannot change the type');
    //                             bankDetailsArr[index][name] = bankDetailsArr[index].deposit_type
    //                             // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: bankDetailsArr[index].deposit_type };
    //                             setState({
    //                                 ...state,
    //                                 bank_information: [...bankDetailsArr]
    //                             });
    //                         }
    //                     }
    //                     else {
    //                         addNewAccount(index, e.target.value)
    //                         bankDetailsArr[index][name] = value
    //                         setState({
    //                             ...state,
    //                             bank_information: [...bankDetailsArr]
    //                         });
    //                         depositTypeOptions[2].disabled = true
    //                         setDepositTypeOptions([...depositTypeOptions])
    //                     }
    //                 }
    //                 else {
    //                     bankDetailsArr[index][name] = value
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                     depositTypeOptions[2].disabled = true
    //                     setDepositTypeOptions([...depositTypeOptions])
    //                 }
    //             }
    //             else if (value == 1) {
    //                 setFullNetIndex(index)
    //                 setFullNetPopup(true)
    //             }
    //             else if (value == 4) {
    //                 state.bank_information[index].deposit_value = ''
    //                 let sumArr = bankDetailsArr.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
    //                 let sumOFF = sumArr.reduce((a, b) => a + b)
    //                 const filteredArray = bankDetailsArr.filter(obj => obj.deposit_type === 3);
    //                 if (sumOFF === 0 && filteredArray.length > 0 && value.deposit_type == 3) {
    //                     let sampleData = []
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     if (deletedArray.length > 0 && deletedArray[index].id) {
    //                         deleteAll = deletedArray.map(obj => obj.id);
    //                     }
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr[index][name] = 1
    //                     bankDetailsArr[index].deposit_value = ''
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: 1, deposit_value: '' };
    //                     sampleData.push(bankDetailsArr[index])
    //                     if (error.length > 0) {
    //                         error[index].deposit_value = ''
    //                     }
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                 } else {
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     deleteAll = deletedArray.map(obj => obj.id);
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr.splice(index + 1);
    //                     bankDetailsArr[index][name] = value
    //                     bankDetailsArr[index].deposit_value = ''
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value, deposit_value: '' };
    //                     if (error.length > 0) {
    //                         error[index].deposit_value = ''
    //                     }
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                 }
    //             }
    //             else {
    //                 state.bank_information[index].deposit_value = '';
    //                 const filteredArray = bankDetailsArr.filter(obj => (obj.deposit_type == 2 || obj.deposit_type == 3));
    //                 if (filteredArray.length == 0) {
    //                     let depositOptions = depositTypeOptions.map(obj => {
    //                         const newObj = { ...obj };

    //                         // Update the specified key with the new value
    //                         newObj['disabled'] = false;

    //                         return newObj;
    //                     })
    //                     setDepositTypeOptions([...depositOptions])
    //                 }
    //                 bankDetailsArr[index][name] = value
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //         }

    //         handleValidate(name, e.target.value, index);
    //     }
    //     else if (name == "deposit_value") {
    //         const filteredArray = bankDetailsArr.filter(obj => obj.deposit_type === 4);
    //         let sumArr = bankDetailsArr.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
    //         let sumOFF = sumArr.reduce((a, b) => a + b)
    //         if (bankDetailsArr[index].deposit_type == 3 && state.bank_information.length !== 5) {
    //             bankDetailsArr[index][name] = value
    //             if (sumOFF > 100) {
    //                 addErrorMsg("Please Enter Valid Deposit Percentage");
    //                 bankDetailsArr[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
    //                 // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value.length == 1 ? 0 : Math.floor(value / 10) };
    //             }
    //             else if (e.target.value == 100 || sumOFF == 100) {
    //                 let deleteAll = deleteId;
    //                 const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                 deleteAll = deletedArray.map(obj => obj.id);
    //                 setdeleteId([...deleteAll])
    //                 bankDetailsArr.splice(index + 1);
    //                 bankDetailsArr[index][name] = value
    //             }
    //             else if (filteredArray.length === 0) {
    //                 bankDetailsArr[index][name] = value
    //                 addNewAccount(index, 3)
    //             }
    //             setState({
    //                 ...state,
    //                 bank_information: [...bankDetailsArr]
    //             });
    //             depositTypeOptions[1].disabled = true;
    //             setDepositTypeOptions([...depositTypeOptions]);
    //         }
    //         else {
    //             if (state.bank_information.length < 5) {
    //                 bankDetailsArr[index][name] = value
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             } else if (state.bank_information.length == 5) {
    //                 if (sumOFF > 100) {
    //                     addErrorMsg("Please Enter Valid Deposit Percentage");
    //                     bankDetailsArr[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value.length == 1 ? 0 : Math.floor(value / 10) };
    //                 } else if (sumOFF == 100 && filteredArray.length == 1) {
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     deleteAll = deletedArray.map(obj => obj.id);
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr.splice(index + 1);
    //                     bankDetailsArr[index][name] = value
    //                 } else {
    //                     bankDetailsArr[index][name] = value
    //                 }
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //                 depositTypeOptions[1].disabled = true;
    //                 setDepositTypeOptions([...depositTypeOptions]);
    //             }
    //         }
    //         handleValidate(name, e.target.value, index);
    //     }
    //     else if (e.target.name == 'bank_name') {
    //         bankDetailsArr[index][name] = capitalize(e.target.value)
    //         setState({
    //             ...state,
    //             bank_information: [...bankDetailsArr]
    //         });
    //         handleValidate(name, e.target.value, index);
    //     }
    //     else {
    //         bankDetailsArr[index][name] = value
    //         setState({
    //             ...state,
    //             bank_information: [...bankDetailsArr]
    //         });
    //         handleValidate(name, e.target.value, index);
    //     }
    // }

    const handleChange = (e, index) => {
        setCurrentIndex(index);
        const name = e.target.name
        const value = e.target.value
        if (name == 'bank_name') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['account_type'] = ''
            // state.bank_information[index]['account_number'] = ''
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
            // error[index]['account_type'] = ''
            // error[index]['account_number'] = ''
            // error[index]['confirm_account_number'] = ''
            // error[index]['routing_number'] = ''
            // error[index]['confirm_routing_number'] = ''
            // error[index]['deposit_type'] = ''
            // error[index]['deposit_value'] = ''
            setError([...error])
            handleValidate(name, value, index)
        } else if (name == 'account_type') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['account_number'] = ''
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state })
        } else if (name == 'account_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'confirm_account_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'routing_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'confirm_routing_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'deposit_type') {
            if (value == 1) {
                if (state.bank_information.length > 1) {
                    setFullNetPopup(true);
                }
                else {
                    state.bank_information[index][name] = value
                    state.bank_information[index]['deposit_value'] = ''
                    error[index]['deposit_value'] = ''
                    setState({ ...state })
                }
            } else {
                if (value == 4) {
                    state.bank_information[index]['deposit_value'] = ''
                    if (error.length > 0) {
                        error[index]['deposit_value'] = ''
                    }
                }
                state.bank_information[index][name] = value
                state.bank_information[index]['deposit_value'] = ''
                setState({ ...state })
            }
        }
        else if (name == 'deposit_value') {
            if (state.bank_information[index].deposit_type == 3) {
                let sumArr = state.bank_information.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
                let sumOFF = sumArr.reduce((a, b) => a + b)
                if (sumOFF > 100) {
                    addWarningMsg('The deposit percentage cannot exceed 100');
                    state.bank_information[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
                    setState({ ...state });
                } else {
                    state.bank_information[index][name] = value
                    setState({ ...state });
                }
            } else {
                state.bank_information[index][name] = value
                setState({ ...state });
            }
        }
        else {
            state.bank_information[index][name] = value
            setState({ ...state })
        }
        handleValidate(name, value, index)
    }

    const handleValidate = (fieldName, value, index) => {
        let bankdetailsArr = state.bank_information;
        let errorsArr = Array.isArray(error) ? [...error] : [];
        if (!errorsArr[index]) {
            errorsArr[index] = {};
        }
        let errors = errorsArr[index];
        switch (fieldName) {
            case "bank_name":
                errors["bank_name"] = validate_bank_name(value, 'bank ');
                break;
            case "account_type":
                errors["account_type"] = validate_emptyField(value);
                break;
            case "account_number":
                errors["account_number"] = validate_accountNumber(value);
                if (bankdetailsArr[index].confirm_account_number !== '' && value !== bankdetailsArr[index].confirm_account_number) {
                    errors["confirm_account_number"] = "Account number not matching";
                } else {
                    errors["confirm_account_number"] = "";
                }
                break;
            case "confirm_account_number":
                if (value == "") {
                    errors["confirm_account_number"] = "This field is required";
                } else if (value != bankdetailsArr[index].account_number) {
                    errors["confirm_account_number"] = "Account number not matching";
                } else {
                    errors["confirm_account_number"] = "";
                }
                break;
            case "routing_number":
                errors["routing_number"] = validate_routingNumber(value);
                if (bankdetailsArr[index].confirm_routing_number !== '' && value != bankdetailsArr[index].confirm_routing_number) {
                    errors["confirm_routing_number"] = "Route number not matching";
                } else {
                    errors["confirm_routing_number"] = "";
                }
                break;
            case "confirm_routing_number":
                if (value == "") {
                    errors["confirm_routing_number"] = "This field is required";
                } else if (value != bankdetailsArr[index].routing_number) {
                    errors["confirm_routing_number"] = "Route number not matching";
                } else {
                    errors["confirm_routing_number"] = "";
                }
                break;
            case "deposit_type":
                errors["deposit_type"] = validate_emptyField(value);
                break;
            case "deposit_value":
                errors["deposit_value"] = validate_deposit_value(value);
                break;
            case "deposit_form_documents":
                w4error["deposit_form_documents"] = validate_emptyField(value);
                setW4error(w4error)
                break;
            case "i9_form_documents":
                i9error["deposit_form_documents"] = validate_emptyField(value);
                setI9error(i9error)
                break;
            case "void_cheque_documents":
                errors["void_cheque_documents"] = validate_emptyField(value);
                break;
            default:
                break;
        }
        errorsArr[index] = errors;
        setError(errorsArr);
    };
    //     let bankdetailsArr = state;
    //     let errorsArr = [...error];
    //     let errors = errorsArr[index];

    //     switch (fieldName) {
    //         case "bank_name":
    //             errors.bank_name = validate_alphaNumeric(value);
    //             break;
    //         case "account_type":
    //             errors.account_type = validate_emptyField(value);
    //             break;
    //         case "account_number":
    //             errors.account_number = validates_Integer(value);
    //             break;
    //         case "confirm_account_number":
    //             if (value == "") {
    //                 errors.confirm_account_number = "This field is required";
    //             } else if (value != bankdetailsArr[index].account_number) {
    //                 errors.confirm_account_number = "Account number not matching";
    //             } else {
    //                 errors.confirm_account_number = "";
    //             }
    //             break;
    //         case "routing_number":
    //             errors.routing_number = validates_Integer(value);
    //             break;
    //         case "confirm_routing_number":
    //             if (value == "") {
    //                 errors.confirm_routing_number = "This field is required";
    //             } else if (value != bankdetailsArr[index].routing_number) {
    //                 errors.confirm_routing_number = "Route number not matching";
    //             } else {
    //                 errors.confirm_routing_number = "";
    //             }
    //             break;
    //         case "deposit_type":
    //             errors.deposit_type = validate_emptyField(value);
    //             break;
    //         case "deposit_value":
    //             if (bankdetailsArr[index].deposit_type == 4) {
    //                 if (value != '' && value != null) {
    //                     errors.deposit_value = validates_float(value);
    //                 }
    //             } else {
    //                 errors.deposit_value = validates_float(value);
    //             }
    //             break;
    //         case "deposit_form_documents":
    //             errors.deposit_form_documents = validate_emptyField(value);
    //             break;
    //         case "void_cheque_documents":
    //             errors.void_cheque_documents = validate_emptyField(value);
    //             break;
    //         default:
    //             break;
    //     }

    //     errorsArr[index] = errors;
    //     setError(errorsArr);
    // };



    // const validateAll = () => {
    //     let {
    //         bank_name,
    //         account_type,
    //         account_number,
    //         confirm_account_number,
    //         routing_number,
    //         confirm_routing_number,
    //         deposit_type,
    //         deposit_value, } = state;
    //     let errors = {};
    //     errors.bank_name = validate_alphaNumeric(bank_name);
    //     errors.account_type = validate_emptyField(account_type);
    //     errors.account_number = validates_Integer(account_number);
    //     if (account_number != confirm_account_number) {
    //         errors.confirm_account_number = 'Account number not matching';
    //     } else {
    //         errors.confirm_account_number = validate_emptyField(confirm_account_number);
    //     }
    //     errors.routing_number = validates_Integer(routing_number);
    //     if (routing_number != confirm_routing_number) {
    //         errors.confirm_routing_number = 'Routing number not matching';
    //     } else {
    //         errors.confirm_routing_number = validates_Integer(confirm_routing_number);
    //     }
    //     errors.deposit_type = validate_emptyField(deposit_type);
    //     if (deposit_type != 4) {
    //         errors.deposit_value = validates_Integer(deposit_value);
    //     } else {
    //         if (deposit_value != '' && deposit_value != null) {
    //             errors.deposit_value = validates_Integer(deposit_value);
    //         }
    //     }



    //     // if (form === "update") {
    //     //     if (state.void_cheque_documents[0].id === "") {
    //     //         errors.document = validate_emptyField(state.documents[0].new_document_id);
    //     //     }
    //     // } else {
    //     //     errors.document = validate_emptyField(state.documents[0].new_document_id);
    //     // }
    //     return errors;
    // };


    const formValidations = () => {
        let result = [];
        let bankdetailsArr = state.bank_information;
        let errorsArr = [];
        w4error.deposit_form_documents = validate_emptyField(state.deposit_form_documents[0].document_name);
        setW4error(w4error)
        i9error.deposit_form_documents = validate_emptyField(state.i9_form_documents[0].document_name);
        setI9error(i9error)
        bankdetailsArr.forEach(bank => {
            let errors = {};
            errors.bank_name = validate_bank_name(bank.bank_name, 'bank ');
            errors.account_type = bank.bank_name == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.account_type);
            errors.account_number = bank.account_type == '' || bank.bank_name.length < 2 ? '' : validate_accountNumber(bank.account_number);
            if (bank.account_number != bank.confirm_account_number) {
                errors.confirm_account_number = 'Account number not matching';
            } else {
                errors.confirm_account_number = bank.account_number == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.confirm_account_number);
            }
            errors.routing_number = (bank.account_number != bank.confirm_account_number) ? '' : validate_routingNumber(bank.routing_number);
            if (bank.routing_number != bank.confirm_routing_number) {
                errors.confirm_routing_number = 'Routing number not matching';
            } else {
                errors.confirm_routing_number = bank.routing_number == '' || bank.bank_name.length < 2 ? '' : validates_Integer(bank.confirm_routing_number);
            }
            errors.deposit_type = bank.confirm_routing_number == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.deposit_type);
            // errors.deposit_value = (bank.deposit_type == 1 || bank.deposit_type == 4 || bank.deposit_type == '') ? '' : validates_float(bank.deposit_value);
            if (bank.deposit_type != 4 && bank.deposit_type != 1 && bank.deposit_type !== '' && bank.deposit_value !== 0 && bank.deposit_value !== '0') {
                errors.deposit_value = validate_deposit_value(bank.deposit_value);
            }
            else {
                if (bank.deposit_value != '' && bank.deposit_value != null && bank.deposit_value !== 0 && bank.deposit_value !== '0') {
                    errors.deposit_value = validate_deposit_value(bank.deposit_value);
                }
            }
            errorsArr.push(errors);
        });
        setError(errorsArr);
        errorsArr.forEach(errVal => {
            let errArr = [];
            Object.keys(errVal).forEach(err => {
                if (errVal[err] != '') {
                    errArr.push(err)
                }
            });
            if (errArr.length > 0) {
                result.push(errArr);
            }
        });
        return result;
    };

    const onNumberOnlyChange = (event) => {
        const keyCode = event.keyCode || event.which
        const keyValue = String.fromCharCode(keyCode)
        const isValid = new RegExp('[0-9]').test(keyValue)
        if (!isValid) {
            event.preventDefault()
            return
        }
    }

    const handleSubmit = () => {
        let errors = formValidations();
        if (errors.length == 0 && w4error.deposit_form_documents == '' && i9error.deposit_form_documents == '') {
            for (let i = 0; i < state.bank_information.length; i++) {
                if (state.bank_information[i].bank_name == '' || state.bank_information[i].bank_name.length < 2) {
                    addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                    return true
                }
            }
            storeBankDetails();
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const storeBankDetails = () => {
        let data = { request_id: LocalStorage.uid(), employee_id: props.employee.id, deposit_form_documents: state.deposit_form_documents, bank_information: state.bank_information, delete_bank_accounts: deleteId, i9_form_documents: state.i9_form_documents, entity: 'bank-details' };
        setLoading(true)
        EmployeeAPI.storeBankDetails(data, editdeleteId).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    getBankDetails();
                    setclearPopup2(false);
                    setForm(false);
                    props.setformEdit(false);
                    props.getEmployeeApi()
                    props.getActivity()
                    setEditdeleteId(null)
                    addSuccessMsg(response.data.message);
                    setLoading(false)
                    setdeleteId([]);
                } else {
                    setLoading(false);
                    addErrorMsg(response.data.message);
                    setclearPopup2(false);
                }
            }, 400)
        });
    }

    // eslint-disable-next-line
    const downloadDoc = (fileUrl) => {
        FileSaver.saveAs(fileUrl);
    }

    const openAddForm = () => {
        setState(
            {
                deposit_form_documents: [
                    {
                        document_name: "",
                        new_document_id: ""
                    }
                ],
                i9_form_documents: [
                    {
                        document_name: "",
                        new_document_id: ""
                    }
                ],
                bank_information: [
                    {
                        bank_name: "",
                        account_type: "",
                        account_number: "",
                        confirm_account_number: "",
                        routing_number: "",
                        confirm_routing_number: "",
                        deposit_type: "",
                        deposit_value: "",
                        void_cheque_documents: [
                            {
                                document_name: "",
                                new_document_id: ""
                            }
                        ],

                    }]
            }
        );
        setError([]);
        setW4error({ deposit_form_documents: '' })
        setI9error({ deposit_form_documents: '' })
        setForm("add");
        props.setformEdit(true)
    }
    // eslint-disable-next-line
    const addNewAccount = (index, value) => {
        let data = state
        data.bank_information.push(
            {
                bank_name: "",
                account_type: "",
                account_number: "",
                confirm_account_number: "",
                routing_number: "",
                confirm_routing_number: "",
                deposit_type: 4,
                deposit_value: "",
                void_cheque_documents: [
                    {
                        id: "",
                        new_document_id: "",
                        document_url: "",
                        document_name: "",
                    }
                ]
            }
        )
        setState({ ...data })

    }
    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const cancelForm = () => {
        setForm(false);
        getBankDetails();
        props.setformEdit(false)
        setError([])
        setW4error({ deposit_form_documents: '' })
    }
    const editDetails = () => {
        if (props.employee.pending_requests.bank_details) {
            setclearPopup3(true)
        }
        else {
            setForm("add"); props.setformEdit(true)
        }
    }

    const changeToFullNet = () => {
        let name = 'deposit_type'
        let value = 1
        const bank_details = { ...state }
        const bankDetailsArr = bank_details.bank_information;
        for (let i = 0; i < bankDetailsArr.length; i++) {
            if (i != currentIndex) {
                deleteId.push(bankDetailsArr[i].id);
                setdeleteId([...deleteId])
            }
        }
        // if (bankDetailsArr[currentIndex]?.id) {
        //     if (currentIndex !== 0) {
        //         deleteAll.push(bankDetailsArr[currentIndex].id)
        //     }
        // }
        // setdeleteId([...deleteAll])
        const keptObject = bankDetailsArr[currentIndex];
        bankDetailsArr.length = 0;
        bankDetailsArr.push(keptObject);
        bankDetailsArr[0][name] = value
        bankDetailsArr[0].deposit_value = ''
        setState({
            ...state,
            bank_information: [...bankDetailsArr]
        });
        let depositOptions = depositTypeOptions.map(obj => {
            const newObj = { ...obj };
            newObj['disabled'] = false;
            return newObj;
        })
        if (fullNetIndex >= 0) {
            let errorBank = error[fullNetIndex] || {}
            errorBank.deposit_value = ''
            error.length = 0
            error.push(errorBank)
            setError([...error])
        }
        setDepositTypeOptions([...depositOptions])
        setFullNetPopup(false)
    }

    return (
        <Grid container p={1}>
            <Text sx={{ paddingLeft: '10px' }} mediumBoldBlack>
                {employee.is_off_boarding_initiated === true && (!LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == false))) ? "Bank" : ""}
            </Text>
            {
                loading ?
                    <LoadingScreen />
                    :
                    form === 'add' && state.bank_information.length > 0 ?
                        <Grid container sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} pl={2} pt={2} pr={1}>
                                <Grid item sm={6} p={2}>
                                    <FileInput
                                        name={"i9_form_documents"}
                                        FileName={state.i9_form_documents[0]?.document_name}
                                        handleChange={(e) => uploadDocs(e, 0, "i9_form_documents")}
                                        label={"I-9 Document"} isDisabled={false}
                                        handleDelete={() => deleteDoc(0, 'i9_form_documents')}
                                        actionState={state.i9_form_documents[0]?.document_name ? 1 : ''}
                                        documentUrl={state.i9_form_documents[0]?.document_url}
                                        error={i9error.deposit_form_documents ? i9error.deposit_form_documents : ""}
                                        state={state.i9_form_documents[0]}
                                    />
                                    <Text errorText> {i9error.deposit_form_documents ? i9error.deposit_form_documents : ""}</Text>
                                    <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#849199 !important", paddingTop: '5px' }}>To view I-9 sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { handleI9Open() }}>Click here</span></Text>
                                    {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}

                                </Grid>
                                <Grid item sm={6} p={2}>
                                    <FileInput
                                        name={"deposit_form_documents"}
                                        FileName={state.deposit_form_documents[0].document_name}
                                        handleChange={(e) => uploadDocs(e, 0, "deposit_form_documents")}
                                        label={"W-4 Form"} isDisabled={false}
                                        handleDelete={() => deleteDoc(0, 'deposit_form_documents')}
                                        actionState={state.deposit_form_documents[0].document_name ? 1 : ''}
                                        documentUrl={state.deposit_form_documents[0].document_url}
                                        error={w4error.deposit_form_documents ? w4error.deposit_form_documents : ""}
                                        state={state.deposit_form_documents[0]}
                                    />
                                    <Text errorText> {w4error.deposit_form_documents ? w4error.deposit_form_documents : ""}</Text>
                                    <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#849199 !important", paddingTop: '5px' }}>To view W-4 sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { handleW4Open() }}>Click here</span></Text>
                                    {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}

                                </Grid>
                            </Grid>
                            {
                                state.bank_information.map((bank, index) => (
                                    <Grid container item spacing={0} pl={2} pt={2} pr={1}>
                                        <Grid container item spacing={0} alignItems='center'>
                                            <Grid item xs={11} md={11} sm={11} lg={11}>
                                                <Box p={1} pl={2.6} pt={2}>
                                                    <Text largeBlue>
                                                        Bank Details - {index + 1}
                                                    </Text>
                                                </Box>
                                            </Grid>
                                            {
                                                state.bank_information.length >= 2 ?
                                                    <Grid item xs={1} md={1} sm={1} lg={1} textAlign='center'>
                                                        <img src={minus} alt='minus' onClick={() => removeBank(index)} style={{ cursor: 'pointer' }} />
                                                    </Grid> : ''
                                            }
                                        </Grid>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                            <Box p={2}>
                                                <FileInput
                                                    name={"void_cheque_documents"}
                                                    FileName={bank.void_cheque_documents[0].document_name}
                                                    handleChange={(e) => uploadDocs(e, index, "void_cheque_documents")}
                                                    label={"Void Cheque (Optional)"} isDisabled={false}
                                                    handleDelete={() => deleteDoc(index, 'void_cheque_documents')}
                                                    actionState={bank.void_cheque_documents[0].document_name ? 1 : ''}
                                                    documentUrl={bank.void_cheque_documents[0].document_url}
                                                    error={error[index] && error[index].void_cheque_documents ? error[index].void_cheque_documents : ""}
                                                    state={bank.void_cheque_documents[0]}
                                                />
                                                <Text errorText> {error[index] && error[index].void_cheque_documents ? error[index].void_cheque_documents : ""}</Text>
                                                <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#849199 !important", paddingTop: '5px' }}>To view void cheque sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { setVoidOpen(true) }}>Click here</span></Text>
                                                {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>

                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'bank_name',
                                                        value: bank.bank_name,
                                                        inputProps: { maxLength: 50 }
                                                    }}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    labelText={'Bank Name'}
                                                    error={error[index] && error[index].bank_name ? error[index].bank_name : ""}
                                                />
                                                <Text errorText> {error[index] && error[index].bank_name ? error[index].bank_name : ""}</Text>
                                            </Box>

                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Select
                                                    label='Account Type'
                                                    options={AccountTypeOptions}
                                                    name='account_type'
                                                    value={bank.account_type}
                                                    onChange={(e) => handleChange(e, index)}
                                                    error={(bank.bank_name != '' && bank.bank_name.length > 1) && error[index] && error[index].account_type ? error[index].account_type : ""}
                                                    disabled={(bank.bank_name == '' || bank.bank_name.length < 2) ? true : false}
                                                />
                                                <Text errorText> {(bank.bank_name != '' && bank.bank_name.length > 1) && error[index] && error[index].account_type ? error[index].account_type : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'account_number',
                                                        value: bank.account_number,
                                                        inputProps: { minLength: 8, maxLength: 12 },
                                                    }}
                                                    disabled={bank.account_type == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    labelText={'Account Number'}
                                                    onKeyPress={onNumberOnlyChange}
                                                    error={bank.account_type != '' && error[index] && error[index].account_number ? error[index].account_number : ""}
                                                />
                                                <Text errorText> {bank.account_type != '' && error[index] && error[index].account_number ? error[index].account_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'confirm_account_number',
                                                        value: bank.confirm_account_number,
                                                        inputProps: { minLength: 8, maxLength: 12 },
                                                    }}
                                                    disabled={bank.account_number == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Confirm Account Number'}
                                                    error={bank.account_number != '' && error[index] && error[index].confirm_account_number ? error[index].confirm_account_number : ""}
                                                />
                                                <Text errorText> {bank.account_number != '' && error[index] && error[index].confirm_account_number ? error[index].confirm_account_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'routing_number',
                                                        value: bank.routing_number,
                                                        inputProps: { maxLength: 9 }
                                                    }}
                                                    disabled={bank.confirm_account_number == '' || (bank.account_number != bank.confirm_account_number) ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Routing Number'}
                                                    error={(bank.confirm_account_number == '' || bank.account_number != bank.confirm_account_number) ? '' : error[index] && error[index].routing_number ? error[index].routing_number : ""}
                                                />
                                                {
                                                    (bank.confirm_account_number == '' || bank.account_number != bank.confirm_account_number) ? '' :
                                                        <Text errorText> {error[index] && error[index].routing_number ? error[index].routing_number : ""}</Text>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'confirm_routing_number',
                                                        value: bank.confirm_routing_number,
                                                        inputProps: { maxLength: 9 }
                                                    }}
                                                    disabled={bank.routing_number == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Confirm Routing Number'}
                                                    error={bank.routing_number != '' && error[index] && error[index].confirm_routing_number ? error[index].confirm_routing_number : ""}
                                                />
                                                <Text errorText> {bank.routing_number != '' && error[index] && error[index].confirm_routing_number ? error[index].confirm_routing_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Select
                                                    label='Choose Deposit Configuration'
                                                    options={index == 0 ? [{ id: 1, value: 'Full Net' }, { id: 2, value: 'Partial $' }, { id: 3, value: 'Partial %' }, { id: 4, value: 'Remainder' }] : depositTypeOptions}
                                                    name='deposit_type'
                                                    value={bank.deposit_type}
                                                    onChange={(e) => handleChange(e, index)}
                                                    error={bank.confirm_routing_number != '' && error[index] && error[index].deposit_type ? error[index].deposit_type : ""}
                                                    disabled={bank.confirm_routing_number == ''}
                                                />
                                                <Text errorText> {bank.confirm_routing_number != '' && error[index] && error[index].deposit_type ? error[index].deposit_type : ""}</Text>
                                                <Text smallLabel sx={{ font: "10px Quicksand !important", color: "#849199 !important", textAlign: 'left' }}>Choose your bank deposit configuration and division</Text>

                                            </Box>
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'deposit_value',
                                                        value: bank.deposit_value,
                                                        type: 'text',
                                                        inputProps: { maxLength: bank.deposit_type == 2 ? 9 : 3 }
                                                    }}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    disabled={bank.deposit_type == 1 || bank.deposit_type == 4 || bank.deposit_type == '' ? true : false}
                                                    labelText={bank.deposit_type == 3 ? 'Deposit Percentage' : `Deposit Value`}
                                                    error={(bank.deposit_type != 1 || bank.deposit_type != 4 || bank.deposit_type != '') && error[index] && error[index].deposit_value ? error[index].deposit_value : ""}
                                                />
                                                <Text errorText> {(bank.deposit_type != 1 || bank.deposit_type != 4 || bank.deposit_type != '') && error[index] && error[index].deposit_value ? error[index].deposit_value : ""}</Text>
                                                <Text smallLabel sx={{ font: "10px Quicksand !important", color: "#849199 !important", textAlign: 'left' }}>Enter the deposit {state.bank_information[0]?.deposit_type == 3 ? 'percentage' : `value`} that you want to have in this bank</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {
                                state.bank_information.length == 5 ? '' :
                                    <Grid item xs={12} px={3.5} pt={2} pb={2}>
                                        <Button addTitleBtn onClick={() => addNewBank()}>Add New Bank</Button>
                                    </Grid>
                            }
                            <Grid item container>
                                <Grid item container>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Box my={2} px={1} pl={3} display={"flex"}>
                                            {
                                                ["add", "update"].includes(form) ?
                                                    <Button cancelOutlineQuicksand onClick={() => { cancelForm() }}>
                                                        Cancel
                                                    </Button> : null
                                            }

                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} pr={1}>
                                        <Box my={2} pr={4} px={1} display={"flex"} justifyContent={"end"}>
                                            <LoadingButton saveLoader loading={loading} onClick={() => { handleSubmit() }}>
                                                {
                                                    form === "update" ? "Update" : form === "add" ? "Save" : null
                                                }
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        state.bank_information.length > 0 ?
                            <Box sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px", height: '67vh', overflow: 'scroll' }}>
                                {state.bank_information.map((item, index) => (
                                    <Grid container pl={2} pt={2}>
                                        <Grid item lg={11} md={11} sm={11} xs={11}>
                                            <Box p={1} pl={2} pt={2}>
                                                <Text largeBlue>
                                                    Bank Details - {index + 1}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        {
                                            index !== 0 ? '' :
                                                employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) ?
                                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                                        <Box p={1} pr={0}>
                                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => {
                                                                if (props.employee.pending_requests.bank_details) {
                                                                    setclearPopup3(true)
                                                                }
                                                                else { editDetails() }
                                                            }} />
                                                        </Box>
                                                    </Grid>
                                                    : ''
                                        }
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Bank Name
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.bank_name === "" ? "--" : capitalizeAndAddSpace(item.bank_name).length > 20 ?
                                                        <BlackToolTip title={capitalizeAndAddSpace(item.bank_name)} placement="right" arrow>
                                                            {capitalizeAndAddSpace(item.bank_name).slice(0, 20) + (capitalizeAndAddSpace(item.bank_name).length > 20 ? "..." : "")}
                                                        </BlackToolTip>
                                                        :
                                                        capitalizeAndAddSpace(item.bank_name)
                                                    }
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Account Type
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.account_type == 1 ? 'Savings' : 'Checking'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Account Number
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.account_number}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Routing Number
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.routing_number}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Deposit Configuration
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.deposit_type == 1 ? 'Full Net' : item.deposit_type == 2 ? 'Partial $' : item.deposit_type == 3 ? 'Partial %' : 'Remainder'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Deposit Value
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {item.deposit_type == 1 || item.deposit_type == 4 ? '-' : item.deposit_type == 2 ? LocalStorage.getCurrencySymbol() : ''} {item.deposit_value}{item.deposit_type == 3 ? '%' : ''}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Void Cheque
                                                </Text>
                                                {item.void_cheque_documents.length > 0 && item.void_cheque_documents[0].document_name !== '' ?
                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item.void_cheque_documents[0], item.void_cheque_documents[0].document_url) }}>
                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBlue>
                                                            {item.void_cheque_documents[0].document_name}
                                                        </Text>
                                                    </Stack>
                                                    : '-'
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item lg={12} md={12} sm={12} xs={12} pl={2}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Text mediumLabel>
                                            W4 Form
                                        </Text>
                                        {state.deposit_form_documents.length > 0 && state.deposit_form_documents[0].document_name !== '' ?
                                            <Stack direction={'row'} pt={1}>
                                                <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                <Text mediumBlue sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(state.deposit_form_documents[0], state.deposit_form_documents[0].document_url) }}>
                                                    {state.deposit_form_documents[0].document_name}
                                                </Text>
                                            </Stack>
                                            : '-'
                                        }

                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} pl={2}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Text mediumLabel>
                                            I-9 Document
                                        </Text>
                                        {state.i9_form_documents?.length > 0 && state.i9_form_documents[0]?.document_name !== '' ?
                                            <Stack direction={'row'} pt={1}>
                                                <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                <Text mediumBlue sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(state.i9_form_documents[0], state.i9_form_documents[0].document_url) }}>
                                                    {state.i9_form_documents[0].document_name}
                                                </Text>
                                            </Stack>
                                            : '-'
                                        }

                                    </Box>
                                </Grid>
                            </Box>
                            :
                            <Grid container>
                                <Box sx={{ height: "55vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }} >
                                    <Box>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <img src={NoDataImg} alt='no-data' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>Bank Details Not Uploaded</Text>
                                        </Grid>
                                        {employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                            <>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload Bank details for this employee by clicking on the</Text>
                                                </Grid>
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                                    <Text mediumLabel sx={{ fontSize: '14px !important' }}>below button.</Text>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            employee.is_off_boarding_initiated === false && (LocalStorage.getUserData().super_admin || (props.rolePermission !== "" && props.rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) ?
                                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                                    <Button finishFilledQuicksandLargeWidth onClick={() => { if (props.employee.pending_requests.bank_details) { setclearPopup3(true) } else { openAddForm() } }}> <img src={Userplus} alt='plus' /> Add Bank Details</Button>
                                                </Grid> : ""
                                        }
                                    </Box>
                                </Box>
                            </Grid>
            }
            <ReusablePopup white iconHide fixedWidth openPopup={fullNetPopup} setOpenPopup={setFullNetPopup} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={offBoardSure} alt='noactive' />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>
                            By selecting Full Net the other bank details will be deleted
                        </Text>
                    </Box>
                    <Box>
                        <Stack direction={"row"} spacing={3}>
                            <CustomButton smallBlueOutline onClick={() => { setFullNetPopup(false) }}>
                                No
                            </CustomButton>
                            <CustomButton loading={false} redBackgroundWidth
                                onClick={changeToFullNet}>
                                Yes, Continue
                            </CustomButton>
                        </Stack>
                    </Box>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>There is an open request to change the details.</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Please take actions before editing the fields.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <Dialog onClose={() => { setVoidOpen(false) }} open={voidOpen} TransitionComponent={Transition}>
                <img src={sampleBankVoidCheque} alt="void" />
            </Dialog>
        </Grid >
    );
}